/**   
 * api接口统一管理
 */
 import { get, post } from './http'
 export const apiAddress = p => post('api/v1/users/my_address/address_edit_before', p);//假的
 export const apiAddressaa = p => get('api/v1/users/my_address/address_edit_before', p);//假的

 export const getBanner = p => post('portals/Portals/getBanner', p);   //获取banner图
 export const getInfo = p => post('portals/Portals/getList', p);       //获取首页列表信息
 export const getCategoryList = p=> post('lgshop/Business/getCategoryList',p); //获取热了吗商城分类信息
 export const getInfodetail = p => get('portals/Portals/getInfo', p);       //获取首页列表详情
 export const getAdvisory = p => get('portal/index/advisory', p);       //获取首页列表详情
  export const getNewsList = p => post('portals/portals/getList', p);       //获取首页列表详情
    export const getNewsInfo = p => post('portals/portals/getInfo', p);       //获取首页列表详情
	export const getNewsType = p => post('portals/portals/gettype', p);       //获取新闻列表分类
	
	export const indexShow = p => post('portal/task/indexShow', p);       //获取新闻列表分类
	export const pushBusinessByOnline = p => post('portal/task/pushBusinessByOnline', p);       //获取新闻列表分类