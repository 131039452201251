<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->
		<Header />
		<!-- 热了吗舒适家暖商城 -->
		
		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>
		
			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>
		
				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView" ></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView"></div>
				</div>
			</div>
		</div>
		
		
		
		
		
		
		<div class="bannerDiv">
		<img src="../assets/banner/solutionBanner.jpg" >
		</div>
		
		<div class="bigView">
			<div class="bigView-itemView">
				<div class="bigView-itemView-titleView">多元订单实时推送</div>
				<div class="bigView-itemView-contentView">
					<div>1.企业用工、市场服务、家庭服务多元化订单一应俱全</div>
					<div>2.众多周边订单实时推送</div>
				</div>
			</div>

			<div class="bigView-itemViewTwo">
				<div class="bigView-itemView-titleView">多模式接单</div>
				<div class="bigView-itemView-contentViewTwo">
					<div>根据不同订单类型智能匹配接单模式，简单易上手</div>

				</div>
			</div>
			<div class="bigView-itemViewThree">
				<div class="bigView-itemView-titleView">订单可视化管理</div>
				<div class="bigView-itemView-contentView">
					<div>每个订单的服务节点进行可视化管理，帮助灵活就业者提</div>
					<div>高订单管处理效率</div>

				</div>
			</div>


			<div class="bigView-itemViewFour">
				<div class="bigView-itemView-titleView">按日保，保安全</div>
				<div class="bigView-itemView-contentViewTwo">
					<div>服务全程购买保险，人身安全有保障</div>

				</div>
			</div>

			<div class="bigView-itemViewFive">
				<div class="bigView-itemView-titleView">课程培训助力技能升级</div>
				<div class="bigView-itemView-contentView">
					<div>专业的技能培训课程，帮助灵活就业者提升职业技能水平</div>


				</div>
			</div>


		</div>





		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../components/header";
	import Footer from "../components/footer";
	import Config from "../request/config.js";
	import {

	} from "../request/api.js";
	export default {
		name: "index",
		data() {
			return {
				baseImgUrl: Config.baseImgUrl,
				play: false,
				value: "",
			};
		},
		created() {
			window.scrollTo(0, 0);
		},
		components: {
			Footer,
			Header
		},
		mounted() {
			// 切换页面时滚动条自动滚动到顶部
			window.scrollTo(0, 0);
		},
		methods: {
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
				localStorage.setItem("palt","1")
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			oddJobClick() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '2',
					}
				})
			},
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
		},
	};
</script>
<style>
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: textfield;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	/* 如果以上代码不起作用就用  */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
</style>

<style lang="scss" scoped>
	::v-deep.el-carousel__container {
		position: relative;
		height: 650px;
	}

	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	//********标题********
	
	.bannerDiv {
	
		// width: 1440px;
		width: 100%;
		height: 490px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		// margin-bottom: 45px;
		// transform: scale(0.8);
		// margin-top: -20px;
		// margin-bottom: -20px;
		margin-top: 14px;
		overflow: hidden;
	}
	
	.bannerDiv img {
		
		height: 100%;
	}
	.banner {
		width: 100%;
		height: 400px;
		margin-top: 10px;
	}
	
	

	.title {
		width: 1280px;
		
		box-sizing: border-box;
		min-width: 1280px;
	
		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				// font-size: 15px;
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					// border-bottom-left-radius: 3px !important;
					// border-bottom-right-radius: 3px !important;
					// border-bottom: 5px solid #ebeaea;
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;
					
					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;
					
						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}




	// ******公告******
	.list {
		list-style: none;
		text-align: center;
		overflow: hidden;
		height: 40px;
		padding-top: 2px;

		li {
			text-align: left;
			height: 40px;
			line-height: 40px;
			color: #565656;
		}

		.toUp {
			margin-top: -40px;
			transition: all 0.5s;
		}
	}

	.btn-primary {
		background-color: #105697;
		border-color: #105697;
		border-radius: 10px;
	}

	.btn-orange {
		background-color: #ff80410;
		border-color: #ff8041;
		border-radius: 10px;
	}

	.platPointer {
		cursor: pointer;
	}

	::v-deep .el-carousel--horizontal {
		overflow-x: visible;
	}

	

	.bigView {
		background-image: url(../assets/oddjobapp/longImg.png);
		width: 1000px;
		height: 2822px;
		background-size: 100% 100%;
		margin: 0 auto;
	}

	.bigView-itemView {
		width: 616px;
		height: 183px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		position: relative;
		top: 147px;
		left: -288px;
	}

	.bigView-itemViewTwo {
		width: 616px;
		height: 183px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;
		top: 400px;
		left: 547px;
	}

	.bigView-itemViewThree {
		width: 616px;
		height: 183px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		position: relative;
		top: 820px;
		left: -149px;
	}

	.bigView-itemViewFour {
		width: 616px;
		height: 183px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;
		top: 1300px;
		left: 547px;
	}

	.bigView-itemViewFive {
		width: 616px;
		height: 183px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		position: relative;
		top: 1475px;
		left: -149px;
	}

	.bigView-itemView-titleView {
		font-size: 36px;
		font-weight: 500;
		text-align: left;
		color: #0b71df;
		margin-bottom: 17px;
	}

	.bigView-itemView-contentView {
		font-size: 24px;
		font-weight: 500;
		text-align: right;
		color: #545454;

		height: 116px;
		width: 616px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
	}

	.bigView-itemView-contentViewTwo {
		font-size: 24px;
		font-weight: 500;
		text-align: left;
		color: #545454;
		height: 116px;
		width: 616px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.bigView-itemView-contentView div:nth-child(1) {
		margin-bottom: 27px;
	}
</style>
