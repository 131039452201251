<template>
  <div>
    <headtop></headtop>
    <img class="pagewoker-banner" src="../assets/img/worker/banner.png" />
    <div class="pagewoker-top">
      <h2 class="pagewoker-titleone">工人就业三大痛点</h2>
      <el-row class="pagewoker-row">
        <el-col :span="7">
          <img class="row-img" src="../assets/img/worker/tabthree.png" />
          <p class="row-title">找活难、挣钱难</p>
          <div class="row-content">
            作为一个找活的劳动工人而言，找到合适的工作难上加难，有的时候站上一天也找不到一个活，而找不到活，挣钱就难，一家几口生活就成了问题；
          </div>
        </el-col>
        <el-col :span="7" :offset="1">
          <img class="row-img" src="../assets/img/worker/tabone.png" />
          <p class="row-title">工资低、强度大</p>
          <div class="row-content">
            作为从事灵活的工人而言，他们有的作为社会的底层，所挣得工资也相对较低，而且零工一般所从事劳动强度也很大，也是一个苦差事；
          </div>
        </el-col>
        <el-col :span="7" :offset="1">
          <img class="row-img" src="../assets/img/worker/tabtwo.png" />
          <p class="row-title">环境差、无保险</p>
          <div class="row-content">
            而就普通的百姓而言，因为从事工作的特殊性，所以作业的环境比较差，而且没有保险，导致在干活的时候有很大的风险。
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="pagewoker-centerleft">
      <div class="centerleftone-text">
        <h2 class="text-title">找零活更简单</h2>
        <div class="text-border"></div>
        <div class="text-content">
          通过零工平台，工人们可以很轻松地找到适合自己的工作，并且一键搞定，找活不再那么难了
        </div>
      </div>
      <img src="../assets/img/worker/oneleft.png" alt="" />
    </div>

    <div class="pagewoker-centerright">
      <img src="../assets/img/worker/oneright.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">劳动强度可选择</h2>
        <div class="text-border"></div>
        <div class="text-content">
          一旦工人们通过注册的平台注册以后，可以根据自己的年龄、身体情况，进而来选择适合自己从事的工作，这样才能达到身体力行
        </div>
      </div>
    </div>

    <div class="pagewoker-centerleft">
      <div class="centerleftone-text">
        <h2 class="text-title">人身有保障</h2>
        <div class="text-border"></div>
        <div class="text-content">
          在零工平台注册的工人，因为跟保险公司签署协议，为此在干活的时候有了人身安全的保障。
        </div>
      </div>
      <img src="../assets/img/worker/twoleft.png" alt="" />
    </div>

    <div class="pagewoker-bottom">
      <div class="bottom-kuang">
        <!-- <p>
          套用模型省时省力，不用冥思苦想，但是，每一个需要设计的内容和体系应该是迥然不同的，所以每一个设计方案也都应该是不可复制的
        </p> -->
        <el-button type="primary" class="bottom-button" @click="open">扫码进入</el-button>
      </div>
    </div>

    <img
      class="pagewoker-bottomimg"
      src="../assets/img/enterprise/bottomkuang.png"
    />

    <foot></foot>
  </div>
</template>

<script>
import headtop from "../components/header";
import foot from "../components/footer";
export default {
  name: "worker",
  components: {
    headtop,
    foot,
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    open() {
        this.$alert( '<img src="https://www.odd-job.net/web/assets/img/pop1.a4cd242f.png">','零工工人端', {
          dangerouslyUseHTMLString: true,
           center: true,
           showConfirmButton:false
        });
      }
  },
};
</script>

<style lang="scss" scoped>
.pagewoker-banner {
  width: 100%;
}

.pagewoker-top {
  padding: 160px 0;
  .pagewoker-titleone {
    text-align: center;
    margin: 20px 0;
    font-size: 42px;
  }

  .pagewoker-row {
    width: 80%;
    margin: 0 10%;
    margin-top: 80px;
    .row-img {
      width: 72px;
      height: 72px;
      display: block;
      margin: 0 auto;
    }
    .row-title {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin: 20px 0;
    }
    .row-content {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      width: 70%;
      margin: 0 auto;
    }
  }
}

.pagewoker-centerleft {
  width: 80%;
  margin: 0 10%;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 650px;
    height: 480px;
  }
  .centerleftone-text {
    width: 50%;
    //   margin: 0 10%;

    .text-title {
      color: #333333;
      font-size: 42px;
    }
    .text-border {
      width: 62px;
      height: 4px;
      background: #004a86;
      border-radius: 6px;
      margin: 20px 0 30px;
    }
    .text-content {
      color: #333333;
      font-size: 16px;
      width: 547px;
      letter-spacing: 1px;
    }
  }
}

.pagewoker-centerright {
  width: 80%;
  margin: 0 10%;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 650px;
    height: 480px;
  }
  .centerleftone-text {
    width: 50%;
    .text-title {
      color: #333333;
      font-size: 42px;
    }
    .text-border {
      width: 62px;
      height: 4px;
      background: #004a86;
      border-radius: 6px;
      margin: 20px 0 30px;
    }
    .text-content {
      color: #333333;
      font-size: 16px;
      width: 547px;
      letter-spacing: 1px;
    }
  }
}

.pagewoker-bottom {
  padding-bottom: 150px;
  .bottom-kuang {
    width: 80%;
    // background: #f4f5f9;
    border-radius: 4px;
    // padding: 74px 0;
    margin: 0 10%;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #0e6db4;
      text-align: center;
    }
    .bottom-button {
      padding: 20px 160px;
      background: #67a5e1;
      border-radius: 10px;
      color: #fff;
      margin: 0 auto;
      font-size: 24px;
      margin-top: 55px;
      display: block;
    }
  }
}

.pagewoker-bottomimg {
  width: 100%;
}
</style>