<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->
		<!-- <smallHeader /> -->
		<Header />

		<!-- <div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				


				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
				</div>
			</div>
		</div> -->

		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView" style="background-color: #ff8041;"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView"></div>
				</div>
			</div>
		</div>


		<!-- <div class="titleDiv">


				<img src="../assets/introduction/logo.png" class="logoImg">
				<div class="titleDivRightDiv">
					<div @click="solution" class="platPointer">解决方案</div>
					<div @click="industrialPark" class="platPointer">数字产业园</div>
					<div @click="investmentPromotion" class="platPointer">招商加盟</div>
					<div @click="journalism" class="platPointer">新闻中心</div>
					<div @click="helpCenter" class="platPointer">帮助中心</div>
					<div @click="aboutWe" class="platPointer">关于零工儿</div>
					<div class="platPointer">
						<span @click="returnHome">返回首页</span>
						<img src="../assets/introduction/returnHomePage.png" class="returnHomePage">
					</div>
				</div>
			</div> -->




		<div class="bannerDiv">




			<img src="../assets/banner/solutionBanner.jpg" fit="scale-down">
		</div>
		<div class="lawAbiding">
			<div>News Odd-job</div>
			<div class="oddJobView">
				<div class="lawAbiding-itemView" :class="{'lawAbiding-itemViewClick':lawAbidingItemViewClickOne}"
					@click="smallIconOneClick">
					<img :src="smalloneImg" />

					<div>企业临时用工</div>
				</div>

				<div class="lawAbiding-itemView" @click="smallIconTwoClick"
					:class="{'lawAbiding-itemViewClick':lawAbidingItemViewClickTwo}">
					<img :src="smallTwoImg" />
					<div>居民日常服务</div>
				</div>

				<div class="lawAbiding-itemView" @click="smallIconThreeClick"
					:class="{'lawAbiding-itemViewClick':lawAbidingItemViewClickThree}">
					<img :src="smallThreeImg" />
					<div>线下市场服务</div>
				</div>

				<div class="lawAbiding-itemView" @click="smallIconFourClick"
					:class="{'lawAbiding-itemViewClick':lawAbidingItemViewClickFour}">
					<img :src="smallFourImg" />
					<div>职业技能培训</div>
				</div>
				<div class="lawAbiding-itemView" @click="smallIconFiveClick"
					:class="{'lawAbiding-itemViewClick':lawAbidingItemViewClickFive}">
					<img :src="smallFiveImg" />
					<div>热了吗暖居商城</div>
				</div>


			</div>
		</div>

		<template v-if="lawAbidingItemViewClickOne">
			<div class="smallTitleView">
				转换传统用工模式，有效降低企业用工成本并控制企业用工风险。
			</div>
			<div class="technologicalProcessDiv">
				<img src="../assets/solution/solutionContent.png" class="technologicalProcessImg" />
			</div>
			<div class="bottomTitleView">
				零工厂—行业服务零工平台
			</div>
			<div class="bottomContentView">
				零工厂适用于企业日常经营，将业务外包给平台，
				平台再将业务分包、众包给进行集群注册的灵活就业者。
				①企业与平台签署协议，并在平台发布需求，平台审核通过后展示任务；
				②灵活就业者通过集群注册系统，成功转变为平台上面的个体工商户；
				③经过平台筛选匹配，挑选适合的灵活就业者完成企业发布的任务；
				④企业对平台支付服务费款项； ⑤平台对灵活就业者进行服务费结算；
				⑥平台为企业开具服务费发票； ⑦利用智能开票功能，
				平台为灵活就业者完税，并取得相应完税凭证。
			</div>
		</template>


		<template v-if="lawAbidingItemViewClickTwo">
			<div class="smallTitleView">
				借助商城与上门服务，从 暖、燃、水角度提高居民
				生活质量。
			</div>
			<div class="technologicalProcessDiv">
				<img src="../assets/solution/solutionContentTwo.png" class="technologicalProcessImg"
					style="height: 300px;" />
			</div>
			<div class="bottomTitleView">
				上门儿——线上服务零工平台
			</div>
			<div class="bottomContentView">
				上门儿适用于居民日常生活，现已拥有7500万热用户+灵活就业者，可实现足不出户在线上购买清洗地热、跑腿送货等服务。
				①用户在平台发布清洗地热的需求，经平台审核后展示任务；
				②灵活就业者通过集群注册系统，成功转变为平台上面的个体工商户；
				③灵活就业者通过平台接单任务，并联系用户进行确认；
				④灵活就业者上门并提供服务；
				⑤用户对灵活就业者的服务进行确认完成，并评价；
				⑥用户对平台支付服务费；
				⑦平台对灵活就业者结算服务费；
				⑧平台为用户开具服务费发票；
				⑨利用智能开票功能，平台为灵活就业者完税，并取得相应完税凭证。
			</div>
		</template>


		<template v-if="lawAbidingItemViewClickThree">
			<div class="smallTitleView">
				线下市场灵活用工，实现
				市场零工收入纳税，提高
				地方政府税收。
			</div>
			<div class="technologicalProcessDiv">
				<img src="../assets/solution/solutionContentThree.png" class="technologicalProcessImg"
					style="height: 430px;" />
			</div>
			<div class="bottomTitleView">
				蹲活儿——线下服务零工平台
			</div>
			<div class="bottomContentView">
				蹲活儿适用于线下市场，例如花鸟鱼虫市场、家具城等需要配送和安装服务的市场。
				①用户到线下市场去购买商家的商品，如鱼缸、家具等；
				②用户在平台发布搬运配送和安装的需求服务，经平台审核后展示任务；
				③灵活就业者通过集群注册系统，成功转变为平台上面的个体工商户；
				④灵活就业者通过平台接单任务，并联系用户进行确认；
				⑤灵活就业者通过展示取货码到商家处取商品；
				⑥灵活就业者送货上门并进行安装服务；
				⑦用户对灵活就业者的服务进行验收，并评价；
				⑧用户对平台支付服务费；
				⑨平台对灵活就业者结算服务费；
				⑩平台为用户开具服务费发票；
				⑪ 利用智能开票功能，平台为灵活就业者完税，并取得相应完税凭证。
			</div>
		</template>


		<template v-if="lawAbidingItemViewClickFour">
			<div class="smallTitleView">
				平台与企业合作，实现技
				能在线培训，形成企业备
				用人才蓄水池。
			</div>
			<div class="technologicalProcessDiv">
				<img src="../assets/solution/solutionContentFour.png" class="technologicalProcessImgFour" />
			</div>
			<div class="bottomTitleView">
				零工学校
			</div>
			<div class="bottomContentView">
				①平台创新与热力企业合作，成立零工学校，向社会进行公开招募希望学习一技之长的社会闲散人员，进行技能培训；
				②成功结业后获得技能资格证书入驻零工儿平台，成为灵活就业者；
				③当用户需要灵活用工人员时，零工儿平台可为用户推荐储备人才进行服务。
			</div>
		</template>

		<template v-if="lawAbidingItemViewClickFive">
			<div class="smallTitleView">
				商家与平台合作，实现线
				上销售商品，解决商家本
				地服务难题。
			</div>
			<div class="technologicalProcessDiv">
				<img src="../assets/solution/solutionContentFive.png" class="technologicalProcessImg"
					style="height: 460px;" />
			</div>
			<div class="bottomTitleView">
				热了吗——舒适家暖居商城
			</div>
			<div class="bottomContentView">
				热了吗商城适用于7500万热用户在线上商城购买商品，可选择配送和安装服务
				① 用户在线上商城购买商品，如家用电器等；
				② 商家通过物流公司把商品配送给用户；
				③ 物流公司送货上门后可发送用户已签收通知给商家；
				④ 商家在平台发布配送和安装的需求服务，经平台审核后展示任务；
				⑤ 灵活就业者通过集群注册系统，成功转变为平台上面的个体工商户；
				⑥ 灵活就业者通过平台接单任务，并联系用户进行确认；
				⑦ 灵活就业者送货上门并进行安装服务；
				⑧ 用户对灵活就业者的服务进行验收，并评价；
				⑨ 平台对灵活就业者结算服务费；
				⑩ 平台对商家支付货款；
				⑪ 商家为平台开具商品发票，平台为用户开具商品发票；
				⑫ 平台为商家开具服务费发票；
				⑬ 利用智能开票功能，平台为灵活就业者完税，并取得相应完税凭证。
			</div>
		</template>






		<Footer></Footer>
	</div>
</template>

<script>
	import Config from "../request/config.js";
	import {
		// getBanner,
		// getInfo,
		getCategoryList,
		// getAdvisory,
	} from "../request/api.js";
	import Header from "../components/header";
	// import smallHeader from "../components/smallHeader.vue";
	import Footer from "../components/footer";
	export default {
		name: "index",
		data() {
			return {
				baseImgUrl: Config.baseImgUrl,
				categoryList: "", // 商城目录

				aboutShow: true,

				CultureShow: false,
				callWeShow: false,
				lawAbidingItemViewClickOne: true,
				lawAbidingItemViewClickTwo: false,
				lawAbidingItemViewClickThree: false,
				lawAbidingItemViewClickFour: false,
				lawAbidingItemViewClickFive: false,
				smalloneImg: require("../assets/solution/smallIconOne-green.png"),
				smallTwoImg: require("../assets/solution/smallIconTwo.png"),
				smallThreeImg: require("../assets/solution/smallIconThree.png"),
				smallFourImg: require("../assets/solution/smallIconFour.png"),
				smallFiveImg: require("../assets/solution/smallIconFive.png"),
			}
		},
		created() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// this.first()
			this.getMailList();
		},
		components: {
			Footer,
			// smallHeader,
			Header
		},


		methods: {
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},

			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},


			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
			localStorage.setItem("palt","1")

			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},

			getMailList() {
				let params = {
					app_type: "pc",
					city_site_id: 2,
				};
				getCategoryList(params).then((res) => {
					// console.log("getMailList", res);
					this.categoryList = res.data[0].child_list;
				});
			},

			// investmentPromotion() {
			// 	this.$router.push({
			// 		name: 'investmentPromotion',
			// 		params: {
			// 			content: '1',
			// 		}
			// 	})
			// },
			// solution() {
			// 	this.$router.push({
			// 		name: 'solution'
			// 	})
			// },
			// industrialPark() {
			// 	this.$router.push({
			// 		name: 'industrialPark',

			// 	})
			// },
			// journalism() {
			// 	this.$router.push({
			// 		name: 'journalism',

			// 	})
			// },

			// helpCenter() {
			// 	this.$router.push({
			// 		name: 'helpcenter',

			// 	})
			// },
			// aboutWe() {
			// 	this.$router.push({
			// 		name: 'PlatformIntroduction',
			// 		params: {
			// 			content: '1',
			// 		}
			// 	})

			// },



			// returnHome() {
			// 	this.$router.push({
			// 		name: 'index',
			// 		params: {
			// 			content: '1',
			// 		}
			// 	})
			// },
			smallIconOneClick() {
				this.lawAbidingItemViewClickOne = true
				this.lawAbidingItemViewClickTwo = false
				this.lawAbidingItemViewClickThree = false
				this.lawAbidingItemViewClickFour = false
				this.lawAbidingItemViewClickFive = false

				this.smalloneImg = require("../assets/solution/smallIconOne-green.png")
				this.smallTwoImg = require("../assets/solution/smallIconTwo.png")
				this.smallThreeImg = require("../assets/solution/smallIconThree.png")
				this.smallFourImg = require("../assets/solution/smallIconFour.png")
				this.smallFiveImg = require("../assets/solution/smallIconFive.png")

			},
			smallIconTwoClick() {
				this.lawAbidingItemViewClickOne = false
				this.lawAbidingItemViewClickTwo = true
				this.lawAbidingItemViewClickThree = false
				this.lawAbidingItemViewClickFour = false
				this.lawAbidingItemViewClickFive = false

				this.smalloneImg = require("../assets/solution/smallIconOne.png")
				this.smallTwoImg = require("../assets/solution/smallIconTwo-green.png")
				this.smallThreeImg = require("../assets/solution/smallIconThree.png")
				this.smallFourImg = require("../assets/solution/smallIconFour.png")
				this.smallFiveImg = require("../assets/solution/smallIconFive.png")
			},
			smallIconThreeClick() {
				this.lawAbidingItemViewClickOne = false
				this.lawAbidingItemViewClickTwo = false
				this.lawAbidingItemViewClickThree = true
				this.lawAbidingItemViewClickFour = false
				this.lawAbidingItemViewClickFive = false
				this.smalloneImg = require("../assets/solution/smallIconOne.png")
				this.smallTwoImg = require("../assets/solution/smallIconTwo.png")
				this.smallThreeImg = require("../assets/solution/smallIconThree-green.png")
				this.smallFourImg = require("../assets/solution/smallIconFour.png")
				this.smallFiveImg = require("../assets/solution/smallIconFive.png")
			},
			smallIconFourClick() {
				this.lawAbidingItemViewClickOne = false
				this.lawAbidingItemViewClickTwo = false
				this.lawAbidingItemViewClickThree = false
				this.lawAbidingItemViewClickFour = true
				this.lawAbidingItemViewClickFive = false
				this.smalloneImg = require("../assets/solution/smallIconOne.png")
				this.smallTwoImg = require("../assets/solution/smallIconTwo.png")
				this.smallThreeImg = require("../assets/solution/smallIconThree.png")
				this.smallFourImg = require("../assets/solution/smallIconFour-green.png")
				this.smallFiveImg = require("../assets/solution/smallIconFive.png")
			},
			smallIconFiveClick() {
				this.lawAbidingItemViewClickOne = false
				this.lawAbidingItemViewClickTwo = false
				this.lawAbidingItemViewClickThree = false
				this.lawAbidingItemViewClickFour = false
				this.lawAbidingItemViewClickFive = true
				this.smalloneImg = require("../assets/solution/smallIconOne.png")
				this.smallTwoImg = require("../assets/solution/smallIconTwo.png")
				this.smallThreeImg = require("../assets/solution/smallIconThree.png")
				this.smallFourImg = require("../assets/solution/smallIconFour.png")
				this.smallFiveImg = require("../assets/solution/smallIconFive-green.png")
			},
		}
	};
</script>

<style scoped lang="scss">
	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	.bannerDiv {


		height: 490px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		// margin-bottom: 45px;
		// transform: scale(0.8);
		// margin-top: -20px;
		// margin-bottom: -20px;
		margin-top: 14px;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.bannerDiv img {

		height: 100%;
	}



	.logoImg {
		width: 197px;
		height: 52px;
	}

	.familyThreeDiv {

		width: 1039px;
		height: 532px;
		margin-top: 80px;
		position: relative;
		margin: 0 auto;



	}

	.familyThreeImg {

		width: 828px;
		height: 532px;
		position: absolute;
		right: 0;


	}

	.familyThreeDivCompany {

		width: 499px;
		height: 396px;
		position: absolute;
		background: linear-gradient(91deg, #f4f6f5 0%, #ffffff 100%);
		left: 0;
		z-index: 11;
		top: 95px;
	}

	.familyDiv {

		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		/* align-items: flex-end; */

		/* background-color: red; */
	}

	.familyLogoImg {

		height: 163px;
		width: 168px;

		margin-top: 47px;
		margin-left: 24px;
	}

	.familyDivTitle {

		margin-top: 105px;
	}

	.familyDivTitle div:nth-child(1) {
		font-size: 36px !important;
		font-weight: 400;
		text-align: left;
		color: #828282;
		line-height: 52px;
	}

	.familyDivTitle div:nth-child(2) {
		font-size: 48px !important;
		font-weight: 400;
		text-align: LEFT;
		color: #105697;
		line-height: 45px;
		margin-top: 17px;
	}

	.familyThreeDivCompanyContent {

		font-size: 19px !important;
		font-weight: Extra Light;
		text-align: LEFT;
		color: #828282;
		margin-top: 57px;
		width: 386px;
		margin-left: 49px;
	}

	.lawAbiding {
		width: 100%;
		height: 276px;
		background: #105697;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		/* 276-115=161 */
		padding-top: 24px;
		padding-bottom: 11px;
		box-sizing: border-box;
	}

	.lawAbiding div:nth-child(1) {
		font-size: 40px;
		font-weight: bold;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.oddJobView {

		height: 165px;
		width: 1280px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		/* background-color: yellow; */
	}



	.paginationView {
		/* height: 297px; */
		width: 100%;
		/* background-color: #0000EE; */
		border-top: 2px solid #e8e8e8;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 30px;
		box-sizing: border-box;
	}

	.lawAbiding-itemView {
		width: 242px;
		height: 165px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 36px 0 39px 0;
		box-sizing: border-box;
		background-color: #105697;
		border-right: 1px solid #226aac;


	}

	.lawAbiding-itemViewClick {
		width: 242px;
		height: 165px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 36px 0 39px 0;
		box-sizing: border-box;
		background-color: #fff;
		border-right: 1px solid #226aac;
	}

	.lawAbiding-itemView img {
		width: 45px;
		height: 45px;

	}

	.lawAbiding-itemView div {
		font-size: 20px;
		font-weight: 400;
		text-align: center;
		width: 242px !important;
		color: #fff !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		/* line-height: 21px; */
	}

	.lawAbiding-itemViewClick div {
		font-size: 20px;
		font-weight: 400;
		text-align: center;
		width: 242px !important;
		color: #3a6286 !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.smallTitleView {
		font-size: 20px;
		font-weight: 400;
		text-align: left;
		color: #a3a3a3;
		line-height: 21px;
		margin: 0 auto;
		margin-top: 35px;
		// width: 900px;
		width:1280px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 97px;
	}

	.technologicalProcessImg {
		// width: 1200px;
		width: 1280px;
		height: 400px;
		margin: 0 auto;
	}

	.technologicalProcessDiv {
		width: 100%;
		height: 358px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.bottomTitleView {
		
		font-size: 36px;
		font-weight: 400;
		text-align: left;
	
		color: #2d83d3;
		line-height: 24px;
		margin-top: 74px;
		// width: 865px;
		width:1280px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-top: 74px;
	}

	.bottomContentView {
		width: 1280px;
		height: auto;
		// font-size: 19px;
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		// color: #bbb9b9;
		color: #666666;
		// line-height: 48px;
		line-height: 36px;
		margin-top: 66px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 20px 0;
	}

	.titleDiv {
		width: 100%;
		/* height: 52px; */
		display: flex;
		font-size: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 80px;


	}

	.titleDivRightDiv {
		/* width: 6.21rem; */
		/* width: 621px; */
		width: 1000px;
		height: 100%;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center
	}

	.titleDivRightDiv div {

		font-size: 16px;

		// font-weight: 700;
		text-align: CENTER;
		color: #2f2e2e;
	}

	.logoImg {
		width: 197px;
		height: 52px;
	}

	.platPointer {
		cursor: pointer;
	}

	.returnHomePage {
		width: 13px;
		height: 13px;
		margin-left: 7px;
	}






	.title {
		width: 1280px;

		box-sizing: border-box;
		min-width: 1280px;

		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				// font-size: 15px;
				// font-size: 15px;
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					// border-bottom-left-radius: 3px !important;
					// border-bottom-right-radius: 3px !important;
					// border-bottom: 5px solid #ebeaea;
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;


					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}






	.technologicalProcessImgFour {
		// width: 1200px;
		width: 1280px;
		height: 150px;
	}
</style>
