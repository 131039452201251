<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->

		<Header />

		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView" style="background-color: #ff8041;"></div>
				</div>
			</div>
		</div>


		<!-- <smallHeader />
		<div class="bannerDiv">
			<div class="titleDiv">
				<img src="../assets/introduction/logo.png" class="logoImg">
				<div class="titleDivRightDiv">
					<div @click="aboutWe"  class="platPointer">零工儿简介</div>
					<div @click="industrialPark" class="platPointer">数字产业园</div>
					<div @click="investmentPromotion" class="platPointer">招商加盟</div>
					<div  class="platPointer">
						<span @click="returnHome">返回首页</span>
						<img src="../assets/introduction/returnHomePage.png" class="returnHomePage">
					</div>
				</div>
			</div> -->
		<div class="bannerDiv">
			<!-- <div class="familyThreeDiv">
				<div class="familyThreeDivCompany">
					<div class="familyDiv">
						<img src="../assets/introduction/familyLogo.png" class="familyLogoImg">
						<div class="familyDivTitle">
							<div>企业 | 家庭 | 市场</div>
							<div>「惠民利企」</div>
						</div>
					</div>
					<div class="familyThreeDivCompanyContent">
						零工儿致力于重构用工关系，打造灵活用工新生态。
					</div>
				</div>
				<img src="../assets/introduction/familyThree.png" class="familyThreeImg">
			</div> -->
			<img src="../assets/banner/aboutWeBanner.jpg">
		</div>
		<div class="oddJob">
			<div class="oddJobTitle">About Odd-job</div>
			<div class="oddJobContentDiv">
				
				<div class="oddJobContentDivSonDiv" @click="CultureClick">
					<!-- <img src="../assets/introduction/oddJobLogo.png">-->
					<div class="Greenbackground" :class="{GreenbackgroundTwo:CultureShow}">
						<img src="../assets/introduction/CultureImg.png">
					</div>

					<div class="oddJobContentDivSonDivOneTitle">零工儿文化</div>
					<div class="oddJobContentDivSonDivTwoTitle">Corporate culture</div>
				</div>
				

				<div class="oddJobContentDivSonDiv" @click="partnerClick">
					<div class="Greenbackground" :class="{GreenbackgroundTwo:partnerShow}">
						<img src="../assets/introduction/partnerImg.png">
					</div>
					<div class="oddJobContentDivSonDivOneTitle">合作伙伴</div>
					<div class="oddJobContentDivSonDivTwoTitle">Cooperative partner</div>
				</div>
				<div class="oddJobContentDivSonDiv" @click="callClick">
					<div class="Greenbackground" :class="{GreenbackgroundTwo:callWeShow}">
						<img src="../assets/introduction/callWeImg.png">
					</div>
					<div class="oddJobContentDivSonDivOneTitle">联系我们</div>
					<div class="oddJobContentDivSonDivTwoTitle">Contact us</div>
				</div>
			</div>

		</div>
		<div class="about" v-show="aboutShow">
			<img src="../assets/introduction/aboutCompany.png" class="aboutImg">
			<div class="aboutDiv">
				<div>关于零工儿</div>
				<div>
					零工儿平台，是国家鼓励培育数字经济的新产业、新业态，由辽宁北软和热付通创始人创办，
					系上海零工儿信息科技有限公司旗下，依托供热暖通、燃气、水务等细分行业海量精准客户群的优势，
					通过集群注册与工商、税务、电子发票信息、金融系统打通，融合大数据、区块链、人工智能等技术，并结合
					「零工学校」开展就业技能培训的一站式用工就业服务平台。 平台致力于重构用工关系，推动新个体经济，
					优化用工模式，打造灵活用工新生态。完全基于真实用工的“场景化”，深耕供热和水电燃等公用事业生态领域，
					为民生企业和供应商提供降低用工风险和成本的平台服务；并下沉居民消费市场的终端，在有商品、物流、人工安装和运维服务的场景，
					着重于连接企业、商家与自由职业者、固定求职者、农村劳动力与城市用户之间的就业服务场景需求，在“活儿”和“人”之间架设一条信息桥梁，
					助力中国企业更加合规、合法永续经营。</div>
			</div>


		</div>
		<div class="cooperativePartner" v-show="partnerShow">
			<div class="cooperativePartnerSonDiv">
				<div class="cooperativePartnerOneTitle">「战略合作伙伴」</div>

				<img src="../assets/introduction/cooperativePartnerOne.png" class="cooperativePartnerOneImg">
			</div>
			<div class="cooperativePartnerSonDivT">
				<div class="cooperativePartnerTwoTitle">「典型客户」</div>

				<img src="../assets/introduction/cooperativePartnerTwo.png" class="cooperativePartnerTwoImg">
			</div>
		</div>
		<div class="callWe">


		</div>
		<div v-show="CultureShow" class="CultureDiv">
			<div class="CultureDivTitle">
				<div>零工儿文化</div>
				<div>Corporate Culture</div>
			</div>
			<div class="CultureDivThreeView">
				<div class="CultureDivThreeViewSonView">
					<img src="../assets/introduction/mission.png">
					<div class="missionTitle">使命</div>
					<div class="CultureDivThreeViewLine"></div>
					<div class="CultureDivThreeViewSonViewContent">
						利于社会 惠民利企
					</div>
				</div>
				<div class="CultureDivThreeViewSonView">
					<img src="../assets/introduction/mission.png">
					<div class="missionTitle">愿景</div>
					<div class="CultureDivThreeViewLine"></div>
					<div class="CultureDivThreeViewSonViewContent hopeDiv">
						致力于解决用工难题，让技有所长之人都有活干！
					</div>
				</div>
				<div class="CultureDivThreeViewSonView">
					<img src="../assets/introduction/mission.png">
					<div class="missionTitle">价值观</div>
					<div class="CultureDivThreeViewLine"></div>
					<div class="CultureDivThreeViewSonViewContent senseDiv">
						守法、利他， 规范、真实。
					</div>
				</div>
			</div>
			<div class="SolutionDiv">
				<img src="../assets/introduction/logo.png">
				<div class="SolutionDivOneTitle">「灵活用工解决方案」</div>
				<div class="SolutionDivTwoTitle">解决用户灵活用工难题，构建闭环式服务体系</div>
				<div class="SolutionDivThreeTitle">企业用工｜市场用工｜家庭用工｜灵活就业｜税筹优化｜技术培训</div>
			</div>
		</div>
		<div class="callWeDiv" v-show="callWeShow">
			<div class="callWeDivTitleDiv">
				<div>联系我们</div>
				<div>Contact us</div>
			</div>
			<div class="companyHeadquarters">
				<img src="../assets/introduction/companyHeadquarters.png">
				<div class="companyHeadquartersContentDiv">
					<div>公司总部</div>
					<div></div>
					<div>上海零工儿信息科技有限公司 <br />办公地址：上海市金山区山阳镇卫清东路2312号4栋4F</div>
				</div>
			</div>
			<div class="companyHeadquarters ompanyHeadMargin">
				<img src="../assets/introduction/RanDdCenter.png">
				<div class="companyHeadquartersContentDiv">
					<div>研发中心</div>
					<div></div>
					<div>零工儿（沈阳）网络开发有限公司 <br />办公地址：辽宁省沈阳市和平区三好街100号华强广场D座写字楼6F</div>
				</div>
			</div>

			<div class="companyHeadquarters ompanyHeadMargin">
				<img src="../assets/introduction/landingCompany.png">
				<div class="companyHeadquartersContentDiv">
					<div>落地公司</div>
					<div></div>
					<div>零工儿（盘锦）信息科技有限公司<br />办公地址：辽宁省盘锦市山县府前大街10号1F</div>
				</div>
			</div>

			<div class="callMePhone">
				<div>联系电话：400-024-0086</div>
				<div>网址：http://www.odd-job.net 邮箱：service@odd-job.net</div>
			</div>


		</div>



		<Footer></Footer>
	</div>
</template>

<script>
	// import smallHeader from "../components/smallHeader.vue";

	import Header from "../components/header";
	import Footer from "../components/footer";
	export default {
		name: "index",
		data() {
			return {
				aboutShow: true,
				partnerShow: false,
				CultureShow: false,
				callWeShow: false,

			}
		},
		created() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			this.first()
		},
		components: {
			Footer,
			// smallHeader
			Header
		},
		mounted() {
			
			if (localStorage.getItem("palt") == '1') {
				this.aboutShow = true
				this.partnerShow = false
				this.CultureShow = false
				this.callWeShow = false
			}
			if (localStorage.getItem("palt") == '2') {
				this.aboutShow = false
				this.partnerShow = false
				this.CultureShow = true
				this.callWeShow = false
			}
			if (localStorage.getItem("palt")== '3') {
				this.aboutShow = false
				this.partnerShow = true
				this.CultureShow = false
				this.callWeShow = false
			}
			if (localStorage.getItem("palt") == '4') {
				this.aboutShow = false
				this.partnerShow = false
				this.CultureShow = false
				this.callWeShow = true
			}
		},


		methods: {
			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
				localStorage.setItem("palt","1")

			},
			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},
			first() {
				console.log("看看这个是几", this.$route.params.content)
				if (this.$route.params.content == '1') {
					this.aboutShow = true
					this.partnerShow = false
					this.CultureShow = false
					this.callWeShow = false
				}
				if (this.$route.params.content == '2') {
					this.aboutShow = false
					this.partnerShow = false
					this.CultureShow = true
					this.callWeShow = false
				}
				if (this.$route.params.content == '3') {
					this.aboutShow = false
					this.partnerShow = true
					this.CultureShow = false
					this.callWeShow = false
				}
				if (this.$route.params.content == '4') {
					this.aboutShow = false
					this.partnerShow = false
					this.CultureShow = false
					this.callWeShow = true
				}
			},
			CultureClick() {
				this.CultureShow = true
				this.aboutShow = false
				this.partnerShow = false
				this.callWeShow = false
				localStorage.setItem("palt","2")
				// this.$router.push({
				// 	path: '/PlatformIntroduction',
				// 	params: {
				// 		content:"2"
				// 	}
				// })
				


			},
			partnerClick() {
				this.aboutShow = false
				this.partnerShow = true
				this.CultureShow = false
				this.callWeShow = false
				localStorage.setItem("palt","3")
				// this.$router.push({
				// 	path: '/PlatformIntroduction',
				// 	params: {
				// 		content:"3"
				// 	}
				// })


			},
			callClick() {
				this.callWeShow = true
				this.aboutShow = false
				this.partnerShow = false
				this.CultureShow = false
				localStorage.setItem("palt","4")
				// this.$router.push({
				// 	path: '/PlatformIntroduction',
				// 	params: {
				// 		content:"4"
				// 	}
				// })
			
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
				
			},

			solution() {
				this.$router.push({
					name: 'solution'
				})
				
			},

			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},

			
		}

	};
</script>

<style scoped lang="scss">
	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	.bannerDiv {
		// width: 100%;
		// height: 490px;
		// margin: 0 auto;
		// // margin-top: 61px;
		// box-sizing: border-box;
		// // margin-bottom: 45px;
		// // transform: scale(0.8);
		// // margin-top: -20px;
		// // margin-bottom: -20px;
		// margin-top: 14px;
		// overflow: hidden;
		height: 490px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		// margin-bottom: 45px;
		// transform: scale(0.8);
		// margin-top: -20px;
		// margin-bottom: -20px;
		margin-top: 14px;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.bannerDiv img {

		height: 100%;
		// width: 100%;
	}

	.titleDiv {
		width: 100%;
		/* height: 52px; */
		display: flex;
		font-size: row;
		justify-content: space-between;
		align-items: center;


	}

	.titleDivRightDiv {
		/* width: 6.21rem; */
		width: 621px;
		height: 100%;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center
	}

	.titleDivRightDiv div {

		font-size: 16px;

		// font-weight: 700;
		text-align: CENTER;
		color: #2f2e2e;
	}

	.logoImg {
		width: 197px;
		height: 52px;
	}


	.familyThreeDiv {

		width: 1039px;
		height: 532px;
		position: relative;
		margin: 0 auto;
	}

	.familyThreeImg {

		width: 828px;
		height: 532px;
		position: absolute;
		right: 0;


	}

	.familyThreeDivCompany {

		width: 499px;
		height: 396px;
		position: absolute;
		background: linear-gradient(91deg, #f4f6f5 0%, #ffffff 100%);
		left: 0;
		z-index: 11;
		top: 95px;
	}

	.familyDiv {

		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		/* align-items: flex-end; */

		/* background-color: red; */
	}

	.familyLogoImg {

		height: 163px;
		width: 168px;

		margin-top: 47px;
		margin-left: 24px;
	}

	.familyDivTitle {

		margin-top: 105px;
	}

	.familyDivTitle div:nth-child(1) {
		font-size: 36px;
		font-weight: 400;
		text-align: left;
		color: #828282;
		line-height: 52px;
	}

	.familyDivTitle div:nth-child(2) {
		font-size: 48px;
		font-weight: 400;
		text-align: LEFT;
		color: #105697;
		line-height: 45px;
		margin-top: 17px;
	}

	.familyThreeDivCompanyContent {

		font-size: 19px;
		font-weight: Extra Light;
		text-align: LEFT;
		color: #828282;
		margin-top: 57px;
		width: 386px;
		margin-left: 49px;
	}

	.oddJob {
		width: 100%;
		height: 384px;
		background: #105697;
	}

	.oddJobTitle {
		font-size: 51px;

		font-weight: 900;
		text-align: CENTER;
		color: #ffffff;
		padding-top: 16px;
	}

	.oddJobContentDiv {

		// width: 1060px;
		width:1280px;
		height: 185px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		margin-top: 45px;
	}

	.oddJobContentDivSonDiv {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 185px;
	}

	.oddJobContentDivSonDiv img {
		width: 103px;
		height: 103px;
	}

	.oddJobContentDivSonDivOneTitle {

		font-size: 27px;

		font-weight: 900;
		text-align: CENTER;
		color: #ffffff;
	}

	.oddJobContentDivSonDivTwoTitle {

		font-size: 16px;

		font-weight: 400;
		text-align: CENTER;
		color: #ffffff;
	}

	.about {
		margin: 0 auto;
		width: 1243px;
	}

	.aboutImg {
		height: 471px;
		width: 745px;
		margin-top: 151px;
		/* margin-left: 339px; */
	}

	.aboutDiv {
		width: 1243px;
		/* margin-left: 339px; */
		margin-bottom: 221px;
		margin-top: 122px;
	}

	.aboutDiv div:nth-child(1) {

		// font-size: 51px;
		font-size: 36px;

		font-weight: 400;
		text-align: CENTER;
		color: #105697;
		text-align: left;
		margin-bottom: 80px;

	}

	.aboutDiv div:nth-child(2) {

		// font-size: 26px;
		font-size: 16px;
		color: #666666;
		font-weight: 400;
		text-align: LEFT;
		// color: #bbb9b9;
		// line-height: 60px;
		line-height: 36px;

	}

	.cooperativePartner {
		width: 1441px;
		margin: 0 auto;
	}

	.cooperativePartnerOneTitle {

		font-size: 42px;

		font-weight: 400;
		text-align: center;
		color: #000000;
		line-height: 52px;
		margin-top: 288px;
		margin-bottom: 94px;
	}

	.cooperativePartnerTwoTitle {
		font-size: 42px;

		font-weight: 400;
		text-align: center;
		color: #000000;
		line-height: 52px;
		margin-top: 176px;
		margin-bottom: 45px;
	}

	.cooperativePartnerOneImg {
		width: 1441px;
		height: 202px;
		/* margin-left: 240px; */
		margin: 0 auto;
	}

	.cooperativePartnerTwoImg {
		width: 1304px;
		height: 664px;
		margin: 0 auto;
		margin-bottom: 199px;
	}

	.cooperativePartnerSonDivT {
		width: 1304px;
		margin: 0 auto;
	}

	.CultureDiv {
		margin-top: 171px;
	}

	.CultureDivTitle {
		/* height: 136px; */
		/* width: 382px; */
		margin: 0 auto;
		margin-bottom: 98px;
	}

	.CultureDivTitle div:nth-child(1) {

		font-size: 70px;

		font-weight: 400;
		text-align: CENTER;
		color: #105697;
	}

	.CultureDivTitle div:nth-child(2) {
		font-size: 45px;

		font-weight: 400;
		text-align: CENTER;
		color: #bbb9b9;
	}

	.CultureDivThreeView {
		height: 757px;
		// width: 1594px;
		width:1280px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.CultureDivThreeViewSonView {
		// width: 505px;
		width:404px;
		height: 100%;
		border: 1px solid #d0d0d0;
		border-radius: 24px 24px 24px 24px;
		/* background-color: red; */
	}

	.CultureDivThreeViewSonView img {
		width: 100%;
		height: 298px;
	}

	.missionTitle {

		font-size: 51px;

		font-weight: 400;
		text-align: CENTER;
		color: #105697;
		margin-top: 82px;
		/* 67 */
		margin-bottom: 20px;
	}

	.CultureDivThreeViewLine {
		// width: 505px;
		width:100%;
		/* height: 497px; */
		border: 1px solid #d0d0d0;
		border-radius: 0px 0px 24px 24px;
	}

	.CultureDivThreeViewSonViewContent {
		width: 204px;
		/* height: 124px; */
		font-size: 51px;

		font-weight: 400;
		text-align: CENTER;
		color: #828282;
		margin: 0 auto;
		margin-top: 47px;
	}

	.hopeDiv {
		width: 368px;

		height: 144px;
		font-size: 40px;

		font-weight: 400;
		text-align: CENTER;
		color: #828282;
	}

	.senseDiv {
		width: 306px;
		height: 124px;
		font-size: 51px;

		font-weight: 400;
		text-align: CENTER;
		color: #828282;
	}

	.SolutionDiv {
		height: 281px;
		// width: 831px;
		width:1280px;
		margin: 0 auto;
		margin-top: 160px;
		margin-bottom: 168px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

	}

	.SolutionDiv img {
		width: 376px;
		margin-bottom: 37px;

		/* height: 52px; */
	}

	.SolutionDivOneTitle {

		height: 52px;
		font-size: 64px;

		font-weight: 400;
		color: #000000;
		line-height: 52px;
		/* letter-spacing: 2px; */
		margin: 0 auto;
		margin-bottom: 23px;
	}

	.SolutionDivTwoTitle {

		height: 52px;
		font-size: 36px;

		font-weight: 400;
		color: #000000;
		line-height: 52px;
		margin: 0 auto;
		margin-bottom: 13px;
	}

	.SolutionDivThreeTitle {

		height: 52px;
		font-size: 24px;

		font-weight: 400;
		color: #999999;
		line-height: 52px;
		letter-spacing: 0px;
		margin: 0 auto;
	}

	.callWeDivTitleDiv {
		height: 136px;
		margin: 0 auto;
		margin-top: 171px;
		margin-bottom: 176px;
	}

	.callWeDivTitleDiv div:nth-child(1) {
		margin: 0 auto;
		// width: 280px;
		// height: 80px;
		font-size: 70px;

		font-weight: 400;
		text-align: CENTER;
		color: #105697;
	}

	.callWeDivTitleDiv div:nth-child(2) {
		margin: 0 auto;
		// width: 232px;
		// height: 54px;
		font-size: 45px;

		font-weight: 400;
		text-align: CENTER;
		color: #bbb9b9;
		margin-top: 2px;
	}

	.companyHeadquarters {
		height: 195px;
		width: 1089px;
		// width:1280px;
		margin: 0 auto;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
	}

	.companyHeadquarters img {
		width: 179px;
		height: 188px;
	}

	.companyHeadquartersContentDiv {
		height: 100%;
		width: 831px;
		// width:1000px;

	}

	.companyHeadquartersContentDiv div:nth-child(1) {
		width: 128px;
		height: 39px;
		font-size: 32px;

		font-weight: 400;
		text-align: LEFT;
		color: #000000;
		margin-bottom: 35px;
	}

	.companyHeadquartersContentDiv div:nth-child(2) {
		height: 0px;
		border: 1px solid #999;
		width: 831px;
		margin-bottom: 35px;
	}

	.companyHeadquartersContentDiv div:nth-child(3) {
		width: 678px;
		height: 84px;
		font-size: 28px;

		font-weight: 400;
		text-align: LEFT;
		color: #999999;
	}

	.ompanyHeadMargin {
		margin-top: 117px;
	}

	.callMePhone {
		height: 126px;
		width: 875px;
		margin: 0 auto;
		margin-top: 176px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 171px;
	}

	.callMePhone div:nth-child(1) {
		width: 671px;
		height: 62px;
		font-size: 51px;

		font-weight: 400;
		text-align: LEFT;
		color: #105697;
		margin: 0 auto;
	}

	.callMePhone div:nth-child(2) {
		width: 875px;
		height: 36px;
		font-size: 30px;

		font-weight: 400;
		text-align: LEFT;
		color: #999999;
		margin: 0 auto;
	}

	.returnHomePage {
		width: 13px;
		height: 13px;
		margin-left: 7px;
	}

	.Greenbackground {
		background-image: url(../assets/introduction/greenbackground.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 103px;
		height: 103px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.GreenbackgroundTwo {
		background-image: url(../assets/introduction/orangeBackground.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 103px;
		height: 103px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.Greenbackground img {
		width: 63px;
		height: 63px;
	}

	.platPointer {
		cursor: pointer;
	}

	//********标题********


	.title {
		width: 1280px;
		// width: 1200px;
		box-sizing: border-box;
		min-width: 1280px;
		// min-width: 1200px;
		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 44px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;

					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}
</style>
