<template>
  <div>
    <headtop></headtop>
    <img class="pageuser-banner" src="../assets/img/user/banner.png" />
    <div class="pageuser-top">
      <h2 class="pageuser-titleone">用户三难</h2>
      <el-row class="pageuser-row">
        <el-col :span="7" class="pageuser-col">
          <img class="row-img" src="../assets/img/user/tabone.png" />
          <p class="row-title">找人难</p>
          <div class="row-content">
            作为用户而言，一旦有活，可能要面临找人非常难的巨大的问题，有的时候甚至很难找到合适的干活的人；
          </div>
        </el-col>
        <el-col :span="7" :offset="1" class="pageuser-col">
          <img class="row-img" src="../assets/img/user/tabtwo.png" />
          <p class="row-title">买商品难</p>
          <div class="row-content">
            作为一个客户而言，很难短时间内找到自己所需要的商品，尤其更多的相关的商品，而通过注册，让用户短期内迅速买到合适的商品；
          </div>
        </el-col>
        <el-col :span="7" :offset="1" class="pageuser-col">
          <img class="row-img" src="../assets/img/user/tabthree.png" />
          <p class="row-title">发票难</p>
          <div class="row-content">
            就客户而言，因为是一种交易，但是客户在购买商品的时候，存在巨大的问题，有的只能开普票，不能开专票，导致困难重重；
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="pageuser-centerleft">
      <img src="../assets/img/user/oneleft.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">迅速找到干活的人</h2>
        <div class="text-border"></div>
        <div class="text-content">
          通过零工平台，让客户最短的时间内找到能为其服务的工人，进而达到高效干活的目的；
        </div>
      </div>
    </div>

    <div class="pageuser-centerright">
      <img src="../assets/img/user/oneright.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">购买商品</h2>
        <div class="text-border"></div>
        <div class="text-content">
          通过零工平台，让所有的商品在零工平台上第一时间就能找到自己中意的产品；
        </div>
      </div>
    </div>

    <div class="pageuser-centerleft">
      <img src="../assets/img/user/twoleft.png" alt="" />
      <div class="centerleftone-text">
        <h2 class="text-title">破解发票问题</h2>
        <div class="text-border"></div>
        <div class="text-content">
          通过零工平台，彻底破解发票难开的问题，让结算和开发票迅速而便捷，破解巨大的难题；
        </div>
      </div>
    </div>

    <div class="pageuser-bottom">
      <div class="bottom-kuang">
        <!-- <p>
          套用模型省时省力，不用冥思苦想，但是，每一个需要设计的内容和体系应该是迥然不同的，所以每一个设计方案也都应该是不可复制的
        </p> -->
        <el-button type="primary" class="bottom-button" @click="open">扫码进入</el-button>
      </div>
    </div>

    <img
      class="pageuser-bottomimg"
      src="../assets/img/enterprise/bottomkuang.png"
    />

    <foot></foot>
  </div>
</template>

<script>
import headtop from "../components/header";
import foot from "../components/footer";
export default {
  name: "user",
  components: {
    headtop,
    foot,
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    open() {
        this.$alert( '<img src="https://www.odd-job.net/web/assets/img/pop3.76798265.png">','零工用户端小程序', {
          dangerouslyUseHTMLString: true,
           center: true,
           showConfirmButton:false
        });
      }
  },
};
</script>

<style lang="scss" scoped>
.pageuser-banner {
  width: 100%;
}

.pageuser-top {
  padding: 160px 0;
  .pageuser-titleone {
    text-align: center;
    margin: 20px 0;
    font-size: 42px;
  }

  .pageuser-row {
    width: 80%;
    margin: 0 10%;
    margin-top: 80px;
    .pageuser-col {
      background: #ffffff;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.09);
      position: relative;
      padding: 30px;
      height: 250px;
      .row-img {
        width: 72px;
        height: 72px;
        position: absolute;
        top: 0;
        right: 0;
      }
      .row-title {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin: 20px 0;
      }
      .row-content {
        font-size: 18px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}

.pageuser-centerleft {
  width: 100%;
  position: relative;
  margin-bottom: 150px;
  img {
    width: 100%;
  }
  .centerleftone-text {
    position: absolute;
    left: 10%;
    top: 35%;
    .text-title {
      color: #333333;
      font-size: 42px;
    }
    .text-border {
      width: 62px;
      height: 4px;
      background: #004A86;
      border-radius: 6px;
      margin: 20px 0 30px;
    }
    .text-content {
      color: #333333;
      font-size: 16px;
      width: 547px;
      letter-spacing: 1px;
    }
  }
}

.pageuser-centerright {
  width: 100%;
  position: relative;
  margin-bottom: 150px;
  img {
    width: 100%;
  }
  .centerleftone-text {
    position: absolute;
    right: 10%;
    top: 35%;
    .text-title {
      color: #333333;
      text-align: right;
      font-size: 42px;
    }
    .text-border {
      width: 62px;
      height: 4px;
      background: #004A86;
      border-radius: 6px;
      margin: 20px 0 30px auto;
    }
    .text-content {
      color: #333333;
      font-size: 16px;
      width: 547px;
      letter-spacing: 1px;
      text-align: right;
    }
  }
}

.pageuser-bottom {
  padding-bottom: 150px;
  .bottom-kuang {
    width: 80%;
    // background: #f4f5f9;
    border-radius: 4px;
    // padding: 74px 0;
    margin: 0 10%;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #0e6db4;
      text-align: center;
    }
    .bottom-button {
      padding: 20px 160px;
      background: #67a5e1;
      border-radius: 10px;
      color: #fff;
      margin: 0 auto;
      font-size: 24px;
      margin-top: 55px;
      display: block;
    }
  }
}

.pageuser-bottomimg {
  width: 100%;
}
</style>