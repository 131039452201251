<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->
		<Header />
		<!-- 热了吗舒适家暖商城 -->
		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>
				<div class="bigImgLeft">
					<div class="bigImgLeftItem" v-for="(item, index) in categoryList" :key="index"
						v-show="item.child_list.length>0">
						<div @click="toRlm" v-for="(items, indexs) in item.child_list" :key="indexs"
							style="height:auto;border-bottom: 1px solid #999999;padding-bottom: 5px;box-sizing: border-box;">
							<!-- background-color: pink; -->
							<div class="bigImgLeftItemTitle">
								<!-- <el-image style="vertical-align: middle;width:15px;height:15px;" :src="items.image"
									fit="cover"></el-image> -->
								<el-image
									style="vertical-align: middle;width:20px;height:20px;padding-right: 16px;line-height: 20px;"
									:src="items.image" fit="cover"></el-image>
								<span class="bigImgLeftItemTitleText">{{ items.category_name  }}</span>
							</div>
							<div class="bigImgLeftItemInfo">
								<span v-for="(itemsss, indexss) in items.child_list" :key="indexss" @click="toRlm"><span
										class="bigImgLeftItemInfoInner">{{itemsss.category_name }}</span>
									<span class="line" v-show="indexss+1 != items.child_list.length">/</span>
								</span>
							</div>

						</div>

					</div>
				</div>
			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView" style="background-color: #ff8041;"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView"></div>

				</div>
			</div>
		</div>
		<!-- 大图和商城服务部分 -->
		<div class="bigImg">
			<!-- style="height:655px;width: 100%;margin:0 auto" -->
			<el-carousel arrow="never">

				<el-carousel-item v-for="(item, index) in banner" :key="index">

					<el-image style="height: 100%;width:100%;max-width: 1920px;" :src="baseImgUrl + item.image"
						@click="toLink(index)" fit="fill">
					</el-image>




					<!-- <el-image style="height: 650px; width: 100%; width: 100%; max-width: 100%;" :src="item.img" @click="toLink(index)"
                        fit="cover">
                    </el-image> -->
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 新部分 -->
		<div style="width:100%;background-color:#fff;height:44px;margin-top:-10px\9\0;min-width: 1280px;
		margin-top: -30px;">
			<div class="notice">
				<div style="float:left;display:inline-block; width:240px;height:44px;"></div>
				<div style="display:flex;align-items:center;line-height:44px;height:44px;font-size:18px;color:#FF8041;">
					<img src="@/assets/newImg/index-icon_02.png" alt="" />
					<span style="font-weight:600;margin:0 20px 0 10px">公告 |</span>
					<ul class="list">
						<li v-for="(item, index) in ulList" :key="item.id" :class="!index && play ? 'toUp' : ''">
							{{ item.msg }}
						</li>
					</ul>
				</div>
			</div>
			<div style="clear:both;"></div>
		</div>




		<div class="hotView">
			<div class='hotView-topView'>
				<div>热门需求</div>
				<div @click="dialogVisible=true">在线发布需求</div>
			</div>
			<div class="hotView-bottomView">
				<div class="hotView-bottomViewOne" v-for="(i,index) in indexShowData" v-show="index==0" :key="index">
					<div class='dddDiv'>
						<div>{{i.task_name}}</div>
						<div>{{i.enterprise_name}}</div>
						<div>￥{{i.task_price}}</div>
					</div>
				</div>
				<div class="hotView-bottomViewTwo" v-for="(i,index) in indexShowData" v-show="index==1" :key="index">
					<div class='dddDiv'>
						<div>{{i.task_name}}</div>
						<div>{{i.enterprise_name}}</div>
						<div>￥{{i.task_price}}</div>
					</div>
				</div>
				<div class="hotView-bottomViewThree" v-for="(i,index) in indexShowData" v-show="index==2" :key="index">
					<div class='dddDiv'>
						<div>{{i.task_name}}</div>
						<div>{{i.enterprise_name}}</div>
						<div>￥{{i.task_price}}</div>
					</div>

				</div>
				<div class="hotView-bottomViewFour" v-for="(i,index) in indexShowData" v-show="index==3" :key="index">
					<div class='dddDiv'>
						<div>{{i.task_name}}</div>
						<div>{{i.enterprise_name}}</div>
						<div>￥{{i.task_price}}</div>
					</div>
				</div>
			</div>
		</div>










		<div class="banner platPointer" @click="oddJobClick">
			<!-- <img src="../assets/newImg/001.png">
			<img src="../assets/newImg/002.png"> -->

			<div class="bannerTitleDiv">
				<!-- <div>灵活用工解决方案</div>
				<div>解决用户灵活用工难题，构建闭环式服务体系</div> -->
				<div>智慧就业用工服务平台</div>
				<div>根除“人找不到活，活找不到人”的用工难题</div>
			</div>

			<div class="bannerImg">

			</div>



			<div class="bannerBottom">
				<img src="../assets/newImg/banner01.png">
				<img src="../assets/newImg/banner02.png">
				<img src="../assets/newImg/banner03.png">
				<img src="../assets/newImg/banner04.png">
				<img src="../assets/newImg/banner05.png">
			</div>



		</div>


		<!-- <div class="chengLine"></div> -->

		<!-- 主内容 -->
		<div class="box" style="margin-top: -50px;">
			<!-- 找活干 -->
			<div class="boxAll">
				<div class="boxLeft">
					<img src="@/assets/newImg/index_img_01.png" />
				</div>



				<div class="boxRight">
					<p class="boxRightTitle">
						<b style="margin-right:20px">找活儿</b>就上零工儿
					</p>
					<p class="boxRightInfo">
						有空，找个活赚点外快
					</p>
					<div class="boxDetailTwo">
						<div class="boxDetailList">
							<!-- <div class="boxTwo" v-for="(item, index) in dunhuoer" :key="index">
								<div class="boxTwoImg">
									<img :src="item.img" alt="" />
								</div>
								<div class="boxTwoTitle">{{ item.title }}</div>
							</div> -->

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobOne.png" alt="" />
								</div>
								<div class="boxTwoTitle">安装搬运工</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobTwo.png" alt="" />
								</div>
								<div class="boxTwoTitle">酒店服务员</div>
							</div>
							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobThree.png" alt="" />
								</div>
								<div class="boxTwoTitle">电脑装配员</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobFour.png" alt="" />
								</div>
								<div class="boxTwoTitle">私房菜厨师</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobFive.png" alt="" />
								</div>
								<div class="boxTwoTitle">自由撰稿人</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobSix.png" alt="" />
								</div>
								<div class="boxTwoTitle">宠物美容师</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobSeven.png" alt="" />
								</div>
								<div class="boxTwoTitle">广告策划师</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobEight.png" alt="" />
								</div>
								<div class="boxTwoTitle">整理收纳师</div>
							</div>

							<div class="boxTwo">
								<div class="boxTwoImg">
									<img src="../assets/indexoddjob/oddjobNight.png" alt="" />
								</div>
								<div class="boxTwoTitle">心理咨询师</div>
							</div>



						</div>
						<div class="rt">
							<!-- <div class="qrcode"> -->
							<img src="@/assets/images/oddjobApp.png" class="oddjobAppImg" />
							<!-- </div> -->
							<div class="txt">扫描下载</div>
							<el-button class="btn-orange" type="primary" @click="oddJobAppClick">零工儿App</el-button>
						</div>
					</div>
					<el-button class="btn-primary" type="primary"
						:style="{ paddingTop: '6px',paddingBottom: '6px',borderRadius: '50px',}"
						@click="oddJobAppClick">更多</el-button>
				</div>
				<!-- <div class="boxRight"> -->
				<!-- <p class="boxRightTitle">
						<b style="margin-right:20px">找活儿</b>就上零工儿
					</p>
					<p class="boxRightInfo">
						有空，找个活赚点外快
					</p>
					<div class="boxDetailOne"> -->
				<!-- 	<el-radio-group class="radio-group" v-model="radio" size="medium">
							<el-radio-button label="兼职"></el-radio-button>
							<el-radio-button label="小时工"></el-radio-button>
							<el-radio-button label="临时工"></el-radio-button>
							<el-radio-button label="计件工"></el-radio-button>
							<el-radio-button label="维修工"></el-radio-button>
							<el-radio-button label="热暖工"></el-radio-button>
						</el-radio-group> -->
				<!-- <div class="hd"> -->
				<!-- <div class="lt">
								<div class="item">
									<div class="label">行业类别</div>
									<el-select class="select" v-model="value" placeholder="请选择">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="item">
									<div class="label">期望报酬</div>
									<el-select class="select" v-model="value" placeholder="请选择">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
							</div> -->
				<!-- <div class="ct">
								<div class="label">工作方式</div>
								<el-select v-model="value" placeholder="请选择">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div> -->
				<!-- <el-button class="btn-primary" type="primary">找活儿</el-button>
							<el-button class="btn-orange" type="primary" @click="oddJobAppClick">工人端APP下载</el-button>
						</div> -->




				<!-- </div> -->



				<!-- 	<el-button class="btn-primary" type="primary" style="margin-top: 10px;"
						:style="{ paddingTop: '6px', paddingBottom: '6px',  borderRadius: '50px',}"
						@click="oddJobAppClick">更多</el-button>
				</div> -->



			</div>

			<!-- 蹲活儿 -->
			<div class="boxAll">
				<div class="boxLeft">
					<img src="@/assets/newImg/index_img_03.png" />
				</div>
				<div class="boxRight">
					<p class="boxRightTitle">
						<b style="margin-right:20px">市场服务</b>就用蹲活儿
					</p>
					<p class="boxRightInfo">
						采购、送货、安装，一站式办齐
					</p>
					<div class="boxDetailTwo">
						<div class="boxDetailList">
							<div class="boxTwo" v-for="(item, index) in dunhuoer" :key="index">
								<div class="boxTwoImg">
									<img :src="item.img" alt="" />
								</div>
								<div class="boxTwoTitle">{{ item.title }}</div>
							</div>
						</div>
						<div class="rt">
							<div class="qrcode">
								<img src="@/assets/newImg/index_img_07.png" />
							</div>
							<div class="txt">微信登录</div>
							<el-button class="btn-orange" type="primary" @click="squattingActivity">蹲活儿小程序</el-button>
						</div>
					</div>
					<el-button class="btn-primary" type="primary" :style="{
              paddingTop: '6px',
              paddingBottom: '6px',
              borderRadius: '50px',
            }" @click="squattingActivity">更多</el-button>
				</div>
			</div>
			<!-- 上门儿 -->
			<div class="boxAll">
				<div class="boxLeft">
					<img src="@/assets/newImg/index_img_02.png" />
				</div>
				<div class="boxRight">
					<p class="boxRightTitle">
						<b style="margin-right:20px">家庭用工</b>就选上门儿
					</p>
					<p class="boxRightInfo">
						疏通、跑腿、清洁，居家生活便民服务
					</p>
					<div class="boxDetailThree">
						<div class="boxDetailList">
							<div class="boxThree" v-for="(item, index) in shangmener" :key="index">
								<div class="boxThreeImg">
									<img :src="item.img" alt="" />
								</div>
								<div class="boxThreeTitle">{{ item.title }}</div>
							</div>
						</div>
						<div class="rt">
							<div class="qrcode">
								<img src="@/assets/newImg/index_img_06.png" />
							</div>
							<div class="txt">微信登录</div>
							<el-button class="btn-orange" type="primary" @click="secondaryPageClick">上门儿小程序</el-button>
						</div>
					</div>
					<el-button class="btn-primary" type="primary" :style="{
              paddingTop: '6px',
              paddingBottom: '6px',
              borderRadius: '50px',
            }" @click="secondaryPageClick">更多</el-button>
				</div>
			</div>
			<!-- 零工通 -->
			<div class="boxAll">
				<div class="boxLeft">
					<img src="@/assets/newImg/index_img_04.png" />
				</div>
				<div class="boxRight">
					<p class="boxRightTitle">
						<b style="margin-right:20px">企业临时用工</b>就在零工儿
					</p>
					<p class="boxRightInfo">合理优化企业资源 入驻园区尊享红利</p>
					<div class="boxDetailFour">
						<div class="boxDetailList">
							<div class="boxFour" v-for="(item, index) in linggongtong" :key="index">
								<div class="boxFourImg">
									<img :src="item.img" alt="" />
								</div>
								<div class="boxFourTitle">{{ item.title }}</div>
							</div>
						</div>
						<div class="rt">
							<div class="qrcode">
								<img src="@/assets/newImg/index_img_08.png" />
							</div>
							<div class="txt">零工儿</div>
							<el-button class="btn-orange" type="primary" @click="solution">在线注册</el-button>
						</div>
					</div>
					<el-button class="btn-primary" type="primary" :style="{
              paddingTop: '6px',
              paddingBottom: '6px',
              borderRadius: '50px',
            }" @click="solution">更多</el-button>
				</div>
			</div>
			<!-- 零工最新资讯 -->
			<div class="boxAll" @click="journalism">
				<div class="boxTopNews">
					<div class="boxTopNewsTitle">
						<div class="boxTopNewsTitleLeft" @click="journalism">
							零工资讯
						</div>
						<div class="boxTopNewsTitleRight">
							<!-- <span @click="journalism" style="cursor:pointer;">MORE+</span> -->
							<p>公司动态 <span>|</span> 行业动态 <span>|</span> 时事摘要 <span>|</span>人才招聘 </p>
						</div>
					</div>
					<div class="bd">
						<div class="lt">
							<div class="boxTopNewsTitleFdiv">
								<div class="boxTopNewsTitle">
									“研技术洁暖
									造绿水青山”—暨锅炉节能环保超低排放与技术改造研讨会盛大召开
								</div>
								<div class="sub-title">
									《盘锦零工工职业培训学校》批核培训范围：计算机UE/UI初级培训、计算机PHP+H5初级培训、人力资源管理师初、中级培训。
								</div>
							</div>
							<div class="content">
								<div class="item">
									<img src="@/assets/newImg/index_img_12.png" />
									<p>
										主动上门问需，找准服务方向！市人才办开展重点领域人才调研（二）
									</p>
								</div>
								<div class="item">
									<img src="@/assets/newImg/index_img_13.png" />
									<p>
										2021-2022年度采暖季供热报停通知
									</p>
								</div>
								<div class="item">
									<img src="@/assets/newImg/index_img_14.png" />
									<p>
										2020年度新接入的新建建筑小区热用户供热缴费卡在线办理方法
									</p>
								</div>

								<div class="items">
									MORE +
								</div>

							</div>
						</div>
						<!-- <div class="rt"> -->
						<!-- <div class="content" style="margin-bottom:12px">
								<img src="@/assets/newImg/index_img_15.png" />
							</div>
							<div class="boxTopNewsTitle">
								喜报：辽宁北软旗下《盘锦零工职业培训学校》正式通过人社部、教育局、行政管理部门审批
							</div> -->
						<!-- <div class="sub-title" style="padding-left:0;margin:20px 0 0">
                《零工职业学校》培训课程设置与规划方案出炉<br />
                《零工儿（盘锦）信息技术有限公式》获公安部颁发顶级行业安全资质<br />
                《零工儿》产品体系蹲活儿项目拟于2021年下半旬上线
              </div> -->
						<!-- </div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="boxLastInfo" @click="firendsClick">
			「 战略合作伙伴 」
		</div>
		<div class="friend" @click="firendsClick">
			<img src="@/assets/newImg/index-icon_01.png" />
		</div>
		<div class="boxLastInfo">
			「 为100万+企业提供超千种灵活用工服务 」
		</div>
		<!-- 底部四部分 -->
		<div class="sectionMainFour">
			<div class="sectionMainFourList">
				<div class="sectionMainFourListItem">
					<div class="sectionMainFourListItemImg">
						<img src="../assets/images/zhuan.png" />
					</div>
					<div class="sectionMainFourListItemText">
						<div>专业企业服务15年</div>
						<div>服务2200万用户，涵盖150+行业</div>
					</div>
				</div>
				<div class="sectionMainFourListGap"></div>
				<div class="sectionMainFourListItem">
					<div class="sectionMainFourListItemImg">
						<img src="../assets/images/bao.png" />
					</div>
					<div class="sectionMainFourListItemText">
						<div>资金安全</div>
						<div>资金交易托管平台，保障资金安全，确认完成再付款</div>
					</div>
				</div>
				<div class="sectionMainFourListGap"></div>
				<div class="sectionMainFourListItem">
					<div class="sectionMainFourListItemImg">
						<img src="../assets/images/shi.png" />
					</div>
					<div class="sectionMainFourListItemText">
						<div>商家实名</div>
						<div>服务商100%实名认证，官方把关，核实身份</div>
					</div>
				</div>
				<div class="sectionMainFourListGap"></div>
				<div class="sectionMainFourListItem">
					<div class="sectionMainFourListItemImg">
						<img src="../assets/images/guan.png" />
					</div>
					<div class="sectionMainFourListItemText">
						<div>全程监控</div>
						<div>交易过程中产生纠纷，官方100%介入</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>

		<el-dialog :visible.sync="dialogVisible" v-show="dialogVisible" @close='closeDialog'>
			<div class='dialogDiv'>
				<div class="dialogDivSpan">
					<div>在线需求发布</div>

				</div>
				<div class='dialogInputDiv' style="margin-top: 50px;">
					需求标题：
					<input placeholder="请输入需求标题" v-model="RequirementTitle" />
				</div>

				<div class='dialogTextareaDiv'>
					<span>需求内容：</span>
					<textarea style="resize:none;width: 524px;
						height: 120px;
						margin-left: 20px;padding:20px;
						border-radius:3px;box-sizing: border-box;
						border: 1px solid #DADADA !important;
						font-size: 14px;
						font-family: 微软雅黑;" 
						placeholder="请输入需求内容" v-model="RequirementContent" />
				</div>
				<div class='dialogInputDiv'>企业名称：<input placeholder="请输入企业名称" v-model="companyTitle" /></div>
				<div class='dialogInputDiv'>金额预算：<input placeholder="请输入金额预算" style="width: 300px;"
						v-model="companyMoney" /><span>元</span></div>
				<div class='dialogInputDiv'>联系电话：
					<input placeholder="请输入联系电话" style="width: 300px;" v-model="companyTel" />
				</div>
				<div class="buttonView">
					<el-button type="primary" class="submitView" @click="submitFun">需求发布</el-button>
				</div>


			</div>
		</el-dialog>


	</div>
</template>

<script>
	import Header from "../components/header";
	import Footer from "../components/footer";
	import Config from "../request/config.js";
	import {
		getBanner,
		getInfo,
		getCategoryList,
		getAdvisory,
		indexShow,
		pushBusinessByOnline
	} from "../request/api.js";
	export default {
		name: "index",
		data() {
			return {
				RequirementTitle: "",
				RequirementContent: "",
				companyTitle: "",
				companyMoney: "",
				companyTel: "",
				indexShowData: [],
				dialogVisible: false,
				baseImgUrl: Config.baseImgUrl,
				defaultCity: "沈阳",
				radio: "兼职",
				isShowLogin: true,
				cityListOne: ["沈阳", "盘锦", "深圳", "上海", "广州", "重庆"],
				cityListTwo: [
					"沈阳",
					"盘锦",
					"深圳",
					"上海",
					"广州",
					"重庆",
					"成都",
					"杭州",
					"郑州",
					"武汉",
					"南京",
					"西安",
				],
				search: "",
				banner: "",
				solveList: "", //解决方案
				coreList: "", //核心产品
				newsList: "", //行业新闻
				companyNewsList: "", //企业资讯
				categoryList: "", // 商城目录
				dialogFormVisible: false,
				form: {
					phone: "",
					text: "",
					resource: "",
				},
				rules: {
					text: [{
						required: true,
						message: "请输入咨询内容",
						trigger: "blur",
					}, ],
					phone: [{
							required: true,
							message: "请输入手机号",
							trigger: "blur",
						},
						{
							validator: function(rule, value, callback) {
								if (/^1[34578]\d{9}$/.test(value) == false) {
									callback(new Error("请输入正确的手机号"));
								} else {
									callback();
								}
							},
							trigger: "blur",
						},
					],
					resource: [{
						required: true,
						message: "请选择咨询类型",
						trigger: "change",
					}, ],
				},
				contentlists: [
					"零工儿产业园",
					"零工学校",
					"上门儿",
					"今日热招",
					"热了吗舒适家居商城",
					"企业加盟",
				],
				dunhuoer: [{
						img: require("../assets/newImg/dh/01.png"),
						title: "老北市花鸟鱼市场",
					},
					{
						img: require("../assets/newImg/dh/02.png"),
						title: "东北陶瓷城",
					},
					{
						img: require("../assets/newImg/dh/03.png"),
						title: "南五参茸批发市场",
					},
					{
						img: require("../assets/newImg/dh/04.png"),
						title: "东北水暖城",
					},
					{
						img: require("../assets/newImg/dh/05.png"),
						title: "九路家具市场",
					},
					{
						img: require("../assets/newImg/dh/06.png"),
						title: "滑翔五金城",
					},
					{
						img: require("../assets/newImg/dh/07.png"),
						title: "南湖五金工具市场",
					},
					{
						img: require("../assets/newImg/dh/08.png"),
						title: "东北摩托车配件市场",
					},
					{
						img: require("../assets/newImg/dh/09.png"),
						title: "西部汽配城",
					},
				],
				shangmener: [{
						img: require("../assets/newImg/sm/01.png"),
						title: "保洁服务",
					},
					{
						img: require("../assets/newImg/sm/02.png"),
						title: "保姆月嫂",
					},
					{
						img: require("../assets/newImg/sm/03.png"),
						title: "搬家货运",
					},
					{
						img: require("../assets/newImg/sm/04.png"),
						title: "房屋维修",
					},
					{
						img: require("../assets/newImg/sm/05.png"),
						title: "家电维修",
					},
					{
						img: require("../assets/newImg/sm/06.png"),
						title: "家电清洗",
					},
					{
						img: require("../assets/newImg/sm/07.png"),
						title: "上门安装",
					},
					{
						img: require("../assets/newImg/sm/08.png"),
						title: "美食厨师",
					},
					{
						img: require("../assets/newImg/sm/09.png"),
						title: "上门化妆",
					},
					{
						img: require("../assets/newImg/sm/10.png"),
						title: "便民服务",
					},
				],
				linggongtong: [{
						img: require("../assets/newImg/lgt/01.png"),
						title: "供暖行业",
					},
					{
						img: require("../assets/newImg/lgt/02.png"),
						title: "公共事业",
					},
					{
						img: require("../assets/newImg/lgt/03.png"),
						title: "交通运输",
					},
					{
						img: require("../assets/newImg/lgt/04.png"),
						title: "物流行业",
					},
					{
						img: require("../assets/newImg/lgt/05.png"),
						title: "软件行业",
					},
					{
						img: require("../assets/newImg/lgt/06.png"),
						title: "广告行业",
					},
					{
						img: require("../assets/newImg/lgt/07.png"),
						title: "餐饮酒店",
					},
					{
						img: require("../assets/newImg/lgt/08.png"),
						title: "洗浴行业",
					},
					{
						img: require("../assets/newImg/lgt/09.png"),
						title: "安保行业",
					},
					{
						img: require("../assets/newImg/lgt/10.png"),
						title: "组织机构",
					},
				],
				hotJob: [
					"力工",
					"油漆工",
					"铺地板",
					"力工",
					"油漆工",
					"铺地板",
					"力工",
					"油漆工",
					"铺地板",
				],
				ulList: [{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
					{
						msg: "贺公司获得沈阳市工业贺信息化局认定的<市级企业技术中心>",
					},
				],
				play: false,
				visible: false,
				value: "",
				options: [],
				// banners:[ {img:require('../assets/images/1.jpg')},{img:require('../assets/images/2.jpg')}]
			};
		},
		created() {
			window.scrollTo(0, 0);
			this.getImg();
			this.getSolve();
			this.getCore();
			this.getNewsList();
			this.getCompanyInfoList();
			this.getMailList();
			let params = {
				type: this.form.resource,
				content: this.form.text,
				phone: this.form.phone,
			};

			indexShow(params).then((res) => {
				if (res.code == 1) {
					this.indexShowData = res.data

					// this.$message({
					// 	message: "咨询成功！",
					// 	type: "success",
					// });
				} else {
					// this.$message.error("咨询失败");
				}
			});
		},
		components: {
			Footer,
			Header,
		},

		mounted() {
			// 切换页面时滚动条自动滚动到顶部
			window.scrollTo(0, 0);
			setInterval(this.startPlay, 2000);




		},

		methods: {
			submitFun() {


				// RequirementTitle:"",
				// RequirementContent:"",
				// companyTitle:"",
				// companyMoney:"",
				// companyTel:"",

				this.dialogVisible = false
				let params = {
					name: this.RequirementTitle,
					business_price: this.companyMoney,
					content: this.RequirementContent,
					enterprise_name: this.companyTitle,
					enterprise_mobile: this.companyTel,

				};

				pushBusinessByOnline(params).then((res) => {
					if (res.code == 1) {


						this.$message({
							message: "发布成功！",
							type: "success",
						});
					} else {
						this.$message.error("发布失败");
						this.dialogVisible = true
					}
				});



			},
			show() {
				this.dialogVisible = true
			},
			handleClose() {
				this.dialogVisible = false
				console.log('close')
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
				localStorage.setItem("palt", "1")

			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},
			secondaryPageClick() {
				this.$router.push({
					name: 'secondaryPage',

				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			squattingActivity() {
				this.$router.push({
					name: 'squattingActivity',

				})
			},

			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			oddJobClick() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '2',
					}
				})
			},
			oddJobAppClick() {
				this.$router.push({
					name: 'oddjobapp',

				})
			},
			firendsClick() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '3',
					}
				})
			},
			startPlay() {
				let that = this;
				that.play = true; //开始播放
				setTimeout(() => {
					that.ulList.push(that.ulList[0]); //将第一条数据塞到最后一个
					that.ulList.shift(); //删除第一条数据
					that.play = false; //暂停播放
				}, 500);
			},
			//跳转到工人
			gotoworker() {
				this.$router.push({
					name: "todayrezhao",
				});
			},
			getImg() {
				let params;
				getBanner(params).then((res) => {
					if (res.code == 1) {
						this.banner = res.data;
					}
				});
			},
			getMailList() {
				let params = {
					app_type: "pc",
					city_site_id: 2,
				};
				getCategoryList(params).then((res) => {
					// console.log("getMailList", res);
					// this.categoryList = res.data[0].child_list;
					this.categoryList = res.data

				});
			},
			getSolve() {
				let params = {
					post_type: 1,
					category_id: 1,
				};
				getInfo(params).then((res) => {
					this.solveList = res.data.data;
				});
			},
			getCore() {
				let params = {
					post_type: 1,
					category_id: 2,
				};
				getInfo(params).then((res) => {
					// console.log("getCore", res);
					this.coreList = res.data.data;
				});
			},
			getNewsList() {
				let params = {
					post_type: 1,
					category_id: 4,
				};
				getInfo(params).then((res) => {
					// console.log("getNewsList", res);
					this.newsList = res.data.data;
				});
			},
			getCompanyInfoList() {
				let params = {
					post_type: 1,
					category_id: 5,
				};
				getInfo(params).then((res) => {
					// console.log("getCompanyInfoList", res);
					this.companyNewsList = res.data.data;
					for (let index = 0; index < this.companyNewsList.length; index++) {
						this.companyNewsList[index].published_time = this.companyNewsList[
								index
							].published_time
							.replace(/-/g, "/")
							.trim()
							.split(" ")[0];
					}
				});
			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.dialogFormVisible = false;
						// console.log(this.form);
						let params = {
							type: this.form.resource,
							content: this.form.text,
							phone: this.form.phone,
						};
						getAdvisory(params).then((res) => {
							if (res.code == 1) {
								this.$message({
									message: "咨询成功！",
									type: "success",
								});
							} else {
								this.$message.error("咨询失败");
							}
						});
					} else {
						// console.log("error submit!!");
						return false;
					}
				});
			},
			toMoreNewsListLeft() {
				this.$router.push({
					path: "/news",
					name: "news",
					params: {
						categoryid: 5,
					},
				});
			},
			toMoreNewsListRight() {
				this.$router.push({
					path: "/news",
					name: "news",
					params: {
						categoryid: 4,
					},
				});
			},
			reLoad() {
				location.reload();
			},
			toOther(postid) {
				// console.log(postid);
				this.$router.push({
					path: "/other",
					name: "other",
					query: {
						postid: postid,
					},
				});
			},
			toLink(index) {
				if (index == 1) {
					this.toRlm();
				}
			},
			toCompanyLogin() {
				window.open("https://seller.odd-job.net/page/login/index.html");
			},
			toEnterprise() {
				this.$router.push({
					path: "/enterprise",
					name: "enterprise",
				});
			},
			toUser() {
				this.$router.push({
					path: "/user",
					name: "user",
				});
			},
			toAppUpload() {
				window.open("http://www.odd-job.net/app/");
			},
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
			toLogin() {
				window.location.href = "https://seller.odd-job.net/page/login/index.html";
			},
			toRegister() {
				window.location.href = "https://seller.odd-job.net/page/login/index.html";
			},

			changeCity: function() {
				this.isShowCityList = !this.isShowCityList;
			},
			chooseCityOne: function(index) {
				this.defaultCity = this.cityListOne[index];
				this.isShowCityList = false;
			},
			chooseCityTwo: function(index) {
				this.defaultCity = this.cityListTwo[index];
				this.isShowCityList = false;
			},
		},
	};
</script>
<style>
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: textfield;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	/* 如果以上代码不起作用就用  */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
</style>

<style lang="scss" scoped>
	::v-deep.el-carousel__container {
		position: relative;
		// height: 100% !important;
	}

	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	//********标题********
	.title {
		width: 1280px;
		// width: 1200px !important;
		box-sizing: border-box;
		min-width: 1280px;

		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				// font-size: 15px;
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				// height: 653px;
				height: 619px;
				// width: 240px;
				// width: 319px;
				width: 344px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				// opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;


				.lineView {
					// border-bottom-left-radius: 3px !important;
					// border-bottom-right-radius: 3px !important;
					// border-bottom: 5px solid #ebeaea;
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;

					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}

	//********banner图********
	.bigImg {
		width: 100%;
		min-width: 1280px;
		margin: 0 auto;
		// height: 600px;

		position: relative;
		z-index: 1;
		overflow: hidden;
		margin-top: 13px;
		max-width: 1920px;
	}

	.bigImg {
		// width:100%;
		height: 100%;
	}

	// ******公告******
	.list {
		list-style: none;
		text-align: center;
		overflow: hidden;
		height: 40px;
		padding-top: 2px;

		li {
			text-align: left;
			height: 40px;
			line-height: 40px;
			color: #565656;
		}

		.toUp {
			margin-top: -40px;
			transition: all 0.5s;
		}
	}

	// ******主内容******
	.box {
		width: 100%;
		padding-top: 50px;

		.boxAll {
			width: 1280px;
			min-width: 1280px;

			margin: 0 auto;
			padding: 70px 0;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px dashed #999;

			.boxLeft {
				width: 333px;
				height: 213px;
			}

			.boxLeft img {
				width: 333px !important;
				height: 213px !important;
			}

			.boxRight {
				position: relative;

				.boxRightTitle {
					color: $font-color;
					font-size: 36px;
					box-sizing: border-box;
				}

				.boxRightInfo {
					color: #1e1c1c;
					font-size: 24px;
					display: inline-block;
					margin: 10px 0;
				}

				.boxDetailOne {
					margin-top: 10px;

					.radio-group {
						::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
							background-color: #105697;
						}

						::v-deep .el-radio-button:first-child .el-radio-button__inner {
							border-radius: 42px 0 0 42px;
						}

						::v-deep .el-radio-button__inner {
							padding: 10px 0;
							width: 164px;
							font-size: 20px;
							border-color: #105697;
						}

						::v-deep .el-radio-button:last-child .el-radio-button__inner {
							border-radius: 0 42px 42px 0;
						}
					}

					.hd {
						display: flex;
						align-items: flex-end;
						padding-top: 10px;

						.lt {
							margin-right: 2%;
							width: 48%;

							.item {
								display: flex;
								align-items: center;
								margin-top: 20px;

								.label {
									padding-right: 20px;
									font-size: 18px;
								}

								.select {
									flex: 1;
								}
							}
						}

						.ct {
							width: 20%;
							margin-right: 2%;

							.label {
								margin-bottom: 10px;
							}
						}
					}
				}

				.boxDetailTwo {
					display: flex;
					align-items: flex-end;
					// width: 900px;
					width: 833px;
					padding-top: 20px;

					.boxDetailList {
						display: flex;
						flex-wrap: wrap;
						flex: 1;
					}

					.rt {
						padding-bottom: 20px;
						width: 137px;
						text-align: center;

						.qrcode {
							position: absolute;
							top: 70px;
							right: 0;
						}

						.txt {
							line-height: 50px;
							font-size: 18px;
						}
					}

					.boxTwo {
						position: relative;
						display: flex;
						align-items: center;
						width: 33.333%;
						padding: 16px 0;

						.boxTwoImg {
							margin-right: 10px;

							img {
								width: 35px;
								height: 35px;
								vertical-align: middle;
							}
						}

						span {
							color: #d5d5d5;
							padding: 0 26px;
						}

						.boxTwoTitle {
							color: $font-color;
							font-size: 18px;
						}

						&::after {
							content: "";
							position: absolute;
							top: 0;
							right: 25px;
							height: 100%;
							border-right: 1px solid #ebeaea;
						}

						// &:nth-of-type(3n)::after {
						// 	border-width: 0;
						// }
					}
				}

				.boxDetailThree {
					display: flex;
					align-items: flex-end;
					width: 900px;

					.boxDetailList {
						display: flex;
						flex-wrap: wrap;
						flex: 1;
					}

					.rt {
						margin-left: 40px;
						padding-bottom: 20px;
						width: 137px;
						text-align: center;

						.qrcode {
							position: absolute;
							top: 0;
							right: 0;
						}

						.txt {
							line-height: 50px;
							font-size: 18px;
						}
					}

					.boxThree {
						display: flex;
						align-items: center;
						padding: 15px 0;
						width: 20%;
						height: 46px;
						color: $font-color;

						.boxThreeImg {
							img {
								vertical-align: middle;
								width: 34px;
								height: 34px;
							}
						}

						.boxThreeTitle {
							position: relative;
							flex: 1;
							margin-left: 8px;
							line-height: 46px;
							font-size: 18px;

							&::after {
								content: "";
								position: absolute;
								top: 0;
								right: 14px;
								height: 100%;
								border-right: 1px solid #ebeaea;
							}
						}

						&:nth-of-type(1),
						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4),
						&:nth-of-type(5) {
							border-bottom: 1px solid #c4c4c4;
						}

						&:nth-of-type(5n) .boxThreeTitle::after {
							border-width: 0;
						}
					}
				}

				.boxDetailFour {
					display: flex;
					align-items: flex-end;
					width: 900px;

					.boxDetailList {
						flex: 1;
						display: flex;
						flex-wrap: wrap;
					}

					.rt {
						margin-left: 30px;
						padding-bottom: 20px;
						text-align: center;

						.qrcode {
							position: absolute;
							top: 40px;
							right: 0;
						}

						.txt {
							display: block;
							line-height: 50px;
							font-size: 18px;
						}
					}

					.boxFour {
						display: flex;
						align-items: center;
						padding: 15px 0;
						width: 20%;
						height: 46px;
						color: $font-color;

						.boxFourImg {
							img {
								width: 30px;
								height: 30px;
								vertical-align: middle;
							}
						}

						.boxFourTitle {
							position: relative;
							flex: 1;
							margin-left: 8px;
							line-height: 46px;
							font-size: 18px;

							&::after {
								content: "";
								position: absolute;
								top: 0;
								right: 14px;
								height: 100%;
								border-right: 1px solid #ebeaea;
							}
						}

						&:nth-of-type(1),
						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4),
						&:nth-of-type(5) {
							border-bottom: 1px solid #c4c4c4;
						}

						&:nth-of-type(5n) .boxFourTitle::after {
							border-width: 0;
						}
					}
				}

				.boxDetailFive {
					.boxFive {
						margin-top: 12px;

						img {
							width: 955px;
							height: 188px;
						}

						.boxFiveList {
							.boxFiveListItem {
								color: #373737;
								margin-right: 30px;
								font-size: 16px;
							}
						}
					}
				}
			}

			.boxTopNews {
				width: 100%;


				.boxTopNewsTitle {
					display: flex;
					display: -ms-flexbox;
					justify-content: space-between;
					-ms-flex-pack: justify;

					.boxTopNewsTitleLeft {
						// padding: 8px 20px;
						// line-height: 1;
						// font-size: 24px;
						// text-align: center;
						// color: #fff;
						// background-color: #105697;
						//

						width: 138px;
						height: 39px;
						background: #105697;
						border-radius: 10px;
						cursor: pointer;
						font-size: 24px;
						line-height: 24px;
						margin: 0;
						padding: 0;
						font-weight: 400;
						color: #ffffff;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;

						// line-height: 52px;
					}

					.boxTopNewsTitleRight {
						flex: 1;
						-ms-flex: 1;
						text-align: right;
						font-size: 16px;

						width: 342px;
						height: 20px;

						font-size: 18px;

						font-weight: 550;

						color: #1e1c1c;
						line-height: 20px;

						span {
							padding: 0 5px;
						}

						p {
							display: inline-block;
							margin-top: 9px;
							text-align: end;
						}
					}
				}

				.bd {
					display: flex;
					padding-top: 50px;

					.lt {
						margin-right: 2%;
						width: 1280px;
						// width: 64%;
					}

					// .rt {
					// 	width: 34%;
					// }
					.boxTopNewsTitleFdiv {
						border-left: 14px solid #105697;
						margin-bottom: 67px;
					}

					.boxTopNewsTitle {
						padding-left: 20px;
						font-size: 24px;
						font-weight: 400;
						color: #373737;
						line-height: 30px;

					}

					.sub-title {
						// margin: 20px 0 30px;
						padding-left: 30px;
						font-size: 14px;
						font-family: FZHei-B01S, FZHei-B01S-Regular;
						font-weight: 400;
						text-align: LEFT;
						color: #999999;
						line-height: 20px;
						margin-top: 5px;

					}

					.content {
						display: flex;
						justify-content: space-between;

						img {
							width: 100%;
							height: 190px;
						}

						.item {
							width: 350px;
							margin-right: 30px;

							p {
								margin-top: 10px;
								font-size: 14px;
								color: #999;
							}
						}

						.items {
							width: 225px;
							height: 190px;
							border-radius: 10px;
							background-color: #105697;
							font-size: 18px;
							font-weight: 400;
							color: #fbfbfb;
							line-height: 20px;
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
		}
	}

	.boxLastInfo {
		margin: 0 auto;
		margin-top: 73px;
		text-align: center;
		color: $font-color;
		font-size: 40px;
		min-width: 1280px;
		width: 100%;

	}

	// ******底部四部分******
	.sectionMainFour {
		padding: 30px 0;
		width: 1280px;

		margin: 0 auto;
		background-color: $white;

		.sectionMainFourList {
			width: 100%;
			margin: 20px 0;
			display: flex;
			display: -ms-flexbox;
			justify-content: space-between;
			-ms-flex-pack: justify;
			cursor: default;

			.sectionMainFourListItem {
				display: flex;
				display: -ms-flexbox;
				width: 287px;
				justify-content: space-around;
				-ms-flex-pack: space-around;

				.sectionMainFourListItemImg {
					width: 49px;
					height: 55px;

					img {
						width: 49px;
						height: 55px;
					}
				}

				.sectionMainFourListItemText {
					width: 220px;
					padding-left: 6%;

					:nth-child(1) {
						font-size: 24px;
						color: #333333;
					}

					:nth-child(2) {
						font-size: 14px;
						color: #999;
						padding: 10px 0;
					}
				}
			}

			.sectionMainFourListGap {
				width: 1px;
				height: 60px;
				border-left: 1px solid #eeeeee;
				margin: 0 10px;
			}
		}
	}

	.notice {
		min-width: 1280px;
		width: 1280px;

		margin: 0 auto;
	}

	.btn-primary {
		background-color: #105697;
		border-color: #105697;
		border-radius: 10px;
		margin-left: 650px;
		margin-top: 24px;



	}

	.btn-orange {
		background-color: #ff8041;
		border-color: #ff8041;
		border-radius: 10px;
	}

	.friend {
		padding: 40px 0 20px;
		width: 1280px;
		margin: 0 auto;

		>img {
			display: block;
			margin: 0 auto;
		}
	}

	.banner {

		// width: 100%;
		// width: 100%;
		// min-width: 1280px;
		min-width: 1400px;
		// width: 100%;
		margin: 0 auto;
		// height: 600px;
		// background: url('../assets/newImg/bannerImg.png') no-repeat center / 100%;
		height: 900px;
		position: relative;
		z-index: 1;
		overflow: hidden;
		margin-top: 13px;
		// background: url('../assets/newImg/bannerImgXin.png') no-repeat center / 100%;
		// background: url('../assets/newImg/bannerImgXin.png') no-repeat center 100%;//最新版的图片
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 100px;

		.bannerTitleDiv {
			position: relative;
			top: -210px;


			// left: -330px;

			left: 100px;



			z-index: 600;

		}

		.bannerTitleDiv div:nth-child(1) {
			font-size: 60px;
			font-weight: bold;
			color: #3E3938;
		}

		.bannerTitleDiv div:nth-child(2) {
			font-size: 30px;
			margin-top: 20px;
			color: #717070;
		}

		.bannerImg {
			width: 700px;
			height: 400px;
			background: url('../assets/newImg/whiteImg.png') no-repeat center / 100%;
			position: relative;
			top: -200px;
			left: 100px;
			// left:900px;
		}

		.bannerBottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			position: absolute;
			bottom: 150px;
			left: 50%;
			margin-left: -640px;
			z-index: 120;


			width: 1280px !important;
			min-width: 1280px !important;

		}



		// max-width: 1920px;

		// @media only screen and (max-width: 1440px) {
		// 	height: 590px;
		// 	background: url('../assets/newImg/bannerImg.png') no-repeat center / 100%;
		// 	background-size: 100% 100%;
		// }
		// @media only screen and (min-width: 1441px) and (max-width: 1680px) {
		// height: 850px;
		// background: url('../assets/newImg/bannerImg.png') no-repeat center / 100%;
		// background-size: 95% 100%;

		// }

		// @media only screen and (min-width: 1681px) {
		// 	height: 1000px;
		// 	background: url('../assets/newImg/bannerImg.png') no-repeat center / 100%;
		// 	background-size: 87% 100%;
		// }

	}







	.platPointer {
		cursor: pointer;
	}

	::v-deep .el-carousel--horizontal {
		overflow-x: visible;
		height: 655px;
	}

	.oddjobAppImg {
		width: 108px;
		height: 108px;
	}

	// .chengLine {
	// 	// border-bottom:1px solid #DFF3FC;
	// 	height: 1px;
	// 	// width: 1380px;
	// 	// width: 1200px;
	// 	width: 1280px;
	// 	background-color: #DFF3FC;
	// 	margin: 0 auto;
	// }
	::v-deep .el-carousel__container {
		// height:655px;
		height: 620px;
	}

	::v-deep .el-carousel__indicators {
		top: 570px;
		z-index: 9999;
	}

	.hotView-topView {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 1280px;
		min-width: 1280px;
		margin: 0 auto;
		padding: 70px 0;
	}

	.hotView-topView div:nth-child(1) {
		font-size: 40px;
		font-weight: normal;
		color: #000000;
		line-height: 64px;
	}

	.hotView-topView div:nth-child(2) {
		font-size: 24px;
		font-weight: 400;
		color: #034582;
		line-height: 64px;
		border: 1px solid #394F98;
		border-radius: 50px;
		padding: 0 40px;
		height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

	}

	.hotView-bottomView {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 1280px;
		min-width: 1280px;
		margin: 0 auto;
	}

	.hotView-bottomView .hotView-bottomViewOne {
		background-image: url(../assets/product/productOne.png);
		width: 25%;
		height: 186px;
		padding: 30px;
		box-sizing: border-box;
	}

	.hotView-bottomView .hotView-bottomViewTwo {
		background-image: url(../assets/product/productTwo.png);
		width: 25%;
		height: 186px;
		padding: 30px;
		box-sizing: border-box;
	}

	.hotView-bottomView .hotView-bottomViewThree {
		background-image: url(../assets/product/productThree.png);
		width: 25%;
		height: 186px;
		padding: 30px;
		box-sizing: border-box;
	}

	.hotView-bottomView .hotView-bottomViewFour {
		background-image: url(../assets/product/productFour.png);
		width: 25%;
		height: 186px;
		padding: 30px;
		box-sizing: border-box;
	}

	.hotView-bottomView .hotView-bottomViewOne .dddDiv div:nth-child(1),
	.hotView-bottomView .hotView-bottomViewTwo .dddDiv div:nth-child(1),
	.hotView-bottomView .hotView-bottomViewThree .dddDiv div:nth-child(1),
	.hotView-bottomView .hotView-bottomViewFour .dddDiv div:nth-child(1) {
		font-size: 24px;
		font-weight: 400;
		color: #000000;
	}

	.hotView-bottomView .hotView-bottomViewOne .dddDiv div:nth-child(2),
	.hotView-bottomView .hotView-bottomViewTwo .dddDiv div:nth-child(2),
	.hotView-bottomView .hotView-bottomViewThree .dddDiv div:nth-child(2),
	.hotView-bottomView .hotView-bottomViewFour .dddDiv div:nth-child(2) {
		font-size: 16px;
		font-weight: 400;
		color: #A2A2A2;
		margin-top: 10px;
	}

	.hotView-bottomView .hotView-bottomViewOne .dddDiv div:nth-child(3),
	.hotView-bottomView .hotView-bottomViewTwo .dddDiv div:nth-child(3),
	.hotView-bottomView .hotView-bottomViewThree .dddDiv div:nth-child(3),
	.hotView-bottomView .hotView-bottomViewFour .dddDiv div:nth-child(3) {
		font-size: 24px;
		font-weight: 400;
		color: #354C96;
		margin-top: 35px;
		margin-left: 140px;
	}

	.dialogDiv {
		// width: 1000px;
		border-radius: 40px;

	}

	::v-deep .el-dialog {
		border-radius: 10px !important;
	}

	.dialogDivSpan {
		border-bottom: 1px solid #DFDFDF;
		width: 100%;
		padding-bottom: 10px;
	}

	.dialogDivSpan div {
		border-left: 5px solid #354C96;
		padding-left: 10px;
		font-size: 18px;
		font-weight: bold;
		color: #000;
	}

	.dialogInputDiv {

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin: 20px 0;
		color: #000;
		padding-left: 50px;
		font-family: monospace;
		
	}

	.dialogInputDiv input {
		height: 40px;
		width:500px;
		border: 1px solid #DADADA;
		margin-left: 20px;
		padding-left: 20px;
		border-radius: 3px;
		font-size: 14px;
		
		font-weight: none;
		font-family:"微软雅黑";
		
	}
	.dialogInputDiv input::-webkit-input-placeholder{
	
	color: #999999;
	
	font-size: 14px;
	
	font-family:"微软雅黑";
	
	}
	
	

	.dialogTextareaDiv {

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 20px 0;
		width: 100%;
		color: #000;
		padding-left: 50px;
	}

	.dialogTextareaDiv textarea {
		resize: none;
		font-size: 14px;
	
		font-family: "微软雅黑";
	}

	.dialogTextareaDiv textarea::-webkit-input-placeholder {
		color: #999999;

		font-size: 14px;

		font-family: "微软雅黑";
	}



	.dialogTextareaDiv span {
		padding-top: 20px;
	}



	.buttonView {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.submitView {
		width: 200px;
		height: 50px;
		background: #354C96;
		border: 2px solid #FFFFFF;
		border-radius: 10px;
		margin-top: 70px;
		margin-bottom: 70px;
	}

	.dialogInputDiv span {
		margin-left: 20px;
	}

	::v-deep.el-dialog__headerbtn ::v-deep.el-dialog__close {
		color: #909399;
		font-size: 30px;
	}
</style>
