<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->


		<Header />
		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView" style="background-color: #ff8041;"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView"></div>
				</div>
			</div>
		</div>




		<!-- <smallHeader />
		<div class="bannerDiv">
			<div class="titleDiv">
				<img src="../assets/introduction/logo.png" class="logoImg">
				<div class="titleDivRightDiv">
					<div @click="solution" class="platPointer">解决方案</div>
					<div @click="industrialPark" class="platPointer">数字产业园</div>
					<div @click="investmentPromotion" class="platPointer">招商加盟</div>
					<div @click="journalism" class="platPointer">新闻中心</div>
					<div @click="helpCenter" class="platPointer">帮助中心</div>
					<div @click="aboutWe" class="platPointer">关于零工儿</div>
					<div class="platPointer">
						<span @click="returnHome">返回首页</span>
						<img src="../assets/introduction/returnHomePage.png" class="returnHomePage">
					</div>
				</div>
			</div> -->
		<div class="bannerDiv">
			<!-- <div class="familyThreeDiv">
				<div class="familyThreeDivCompany">
					<div class="familyDiv">
						<img src="../assets/introduction/familyLogo.png" class="familyLogoImg">
						<div class="familyDivTitle">
							<div>企业 | 家庭 | 市场</div>
							<div>「惠民利企」</div>
						</div>
					</div>
					<div class="familyThreeDivCompanyContent">
						让我们一起定义灵活用工的未来。
					</div>
				</div>
				<img src="../assets/helpCenter/helpCenterTop.png" class="familyThreeImg">
			</div> -->
			<img src="../assets/banner/helpCenterBanner.jpg">

		</div>
		<div class="lawAbiding">
			<div>Help Odd-job</div>
			<div>守法 利他 合规 真实</div>
		</div>
		<div class="callPhoneView">
			<div class="callPhoneViewTitleView">
				零工儿 <br />一站式共享用工平台
			</div>
			<div class="callPhoneViewnumberView">
				<div class="callPhoneViewnumberViewTitleView">
					<img src="../assets/helpCenter/helpCenterPhone.png">
					<div>咨询热线</div>
				</div>
				<div>400-024-0086</div>
			</div>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../components/header";
	// import smallHeader from "../components/smallHeader.vue";
	import Footer from "../components/footer";
	export default {
		name: "index",
		data() {
			return {
				aboutShow: true,

				CultureShow: false,
				callWeShow: false,

			}
		},
		created() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			this.first()
		},
		components: {
			Footer,
			// smallHeader
			Header
		},


		methods: {
			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},

			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
			localStorage.setItem("palt","1")

			},



			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},


		}

	};
</script>

<style scoped lang="scss">
	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	.bannerDiv {

		// width: 100%;
		// height: 490px !important;
		// margin: 0 auto;
		// // margin-top: 61px;
		// box-sizing: border-box;
		// // margin-bottom: 45px;
		// // transform: scale(0.8);
		// // margin-top: -20px;
		// // margin-bottom: -20px;
		// margin-top: 14px;
		// overflow: hidden;
		height: 490px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		// margin-bottom: 45px;
		// transform: scale(0.8);
		// margin-top: -20px;
		// margin-bottom: -20px;
		margin-top: 14px;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

	}











	.logoImg {
		width: 197px;
		height: 52px;
	}

	.familyThreeDiv {

		width: 1039px;
		height: 532px;
		position: relative;
		margin: 0 auto;
	}

	.familyThreeImg {

		width: 828px;
		height: 532px;
		position: absolute;
		right: 0;


	}

	.familyThreeDivCompany {

		width: 499px;
		height: 396px;
		position: absolute;
		background: linear-gradient(91deg, #f4f6f5 0%, #ffffff 100%);
		left: 0;
		z-index: 11;
		top: 95px;
	}

	.familyDiv {

		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		/* align-items: flex-end; */

		/* background-color: red; */
	}

	.familyLogoImg {

		height: 163px;
		width: 168px;

		margin-top: 47px;
		margin-left: 24px;
	}

	.familyDivTitle {

		margin-top: 105px;
	}

	.familyDivTitle div:nth-child(1) {
		font-size: 36px;
		font-weight: 400;
		text-align: left;
		color: #828282;
		line-height: 52px;
	}

	.familyDivTitle div:nth-child(2) {
		font-size: 48px;
		font-weight: 400;
		text-align: LEFT;
		color: #105697;
		line-height: 45px;
		margin-top: 17px;
	}

	.familyThreeDivCompanyContent {

		font-size: 19px;
		font-weight: Extra Light;
		text-align: LEFT;
		color: #828282;
		margin-top: 57px;
		width: 386px;
		margin-left: 49px;
	}


	.lawAbiding {
		width: 100%;
		height: 276px;
		background: #105697;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		/* 276-115=161 */
		padding-top: 61px;
		padding-bottom: 100px;
		box-sizing: border-box;
	}

	.lawAbiding div:nth-child(1) {
		font-size: 40px;
		font-weight: bold;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.lawAbiding div:nth-child(2) {
		font-size: 40px;
		font-weight: 400;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.callPhoneView {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: 105px;
		margin-bottom: 250px;
	}

	.callPhoneViewTitleView {
		// width: 452px;
		width:1280px;
		height: 132px;
		font-size: 50px;
		font-weight: 400;
		text-align: center;
		color: #105697;
		line-height: 84px;
		margin-bottom: 105px;

	}

	.callPhoneViewnumberView {
		/* width: 269px; */
		height: 95px;
		font-size: 40px;
		font-weight: 400;
		text-align: left;
		color: #6b6b6b;
		line-height: 60px;

	}

	.callPhoneViewnumberView img {
		width: 40px;
		height: 40px;
		margin-right: 20px;
	}

	.callPhoneViewnumberViewTitleView {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.titleDiv {
		width: 100%;
		/* height: 52px; */
		display: flex;
		font-size: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 80px;


	}

	.titleDivRightDiv {
		/* width: 6.21rem; */
		/* width: 621px; */
		width: 1000px;
		height: 100%;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center
	}

	.titleDivRightDiv div {

		font-size: 16px;

		// font-weight: 700;
		text-align: CENTER;
		color: #2f2e2e;
	}

	.logoImg {
		width: 197px;
		height: 52px;
	}

	.platPointer {
		cursor: pointer;
	}

	.returnHomePage {
		width: 13px;
		height: 13px;
		margin-left: 7px;
	}

	//********标题********

	.title {
		width: 1280px;

		box-sizing: border-box;
		min-width: 1280px;

		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {

				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;

				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;

					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}
</style>
