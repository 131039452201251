<template>
  <div class="rezhaopage">
    <headtop></headtop>
    <img
      class="todayrezhao-banner"
      src="../assets/img/todayrezhao/banner1.jpg"
    />
    <div class="todayrezhao-top">
      <h1 class="todayrezhao-title">面临问题</h1>
      <el-row type="flex" class="todayrezhao-row">
        <el-col
          class="todayrezhao-col"
          :span="7"
          :class="choseindex == '1' ? 'checked' : ''"
          @mouseover.native="choseindex = '1'"
          @mouseout.native="choseindex = '-1'"
        >
          <img class="col-img" src="../assets/img/todayrezhao/tabone.png" />
          <p class="col-toptext">招工困境</p>
          <p class="col-bottomtext">
            因为行业的特殊性，一些行业频繁出现用工荒，招工难的系列相关的问题
          </p>
        </el-col>
        <el-col
          class="todayrezhao-col"
          :span="7"
          :class="choseindex == '2' ? 'checked' : ''"
          @mouseover.native="choseindex = '2'"
          @mouseleave.native="choseindex = '-1'"
        >
          <img class="col-img" src="../assets/img/todayrezhao/tabtwo.png" />
          <p class="col-toptext">用工风险</p>
          <p class="col-bottomtext">
            过渡期，企业和工人未确立雇佣关系，无约束力、风险高、是否匹配等问题
          </p>
        </el-col>
        <el-col
          class="todayrezhao-col"
          :span="7"
          :class="choseindex == '3' ? 'checked' : ''"
          @mouseover.native="choseindex = '3'"
          @mouseleave.native="choseindex = '-1'"
        >
          <img class="col-img" src="../assets/img/todayrezhao/tabthree.png" />
          <p class="col-toptext">工时界定</p>
          <p class="col-bottomtext">
            用工模式存在工作时间延长的情况，这样涉及到工资如何合理结算的问题
          </p>
        </el-col>
      </el-row>
    </div>

    <div class="todayrezhao-center">
      <h1 class="todayrezhao-title">今日热招 — 解决固定员工招聘难题</h1>
      <img src="../assets/img/todayrezhao/bannertwo.png" />
    </div>

    <div class="todayrezhao-bottom">
      <div class="todayrezhao-xuxian"></div>
      <h1 class="todayrezhao-title">入驻流程说明</h1>
      <el-row type="flex" class="todayrezhao-row">
        <el-col class="todayrezhao-col" :span="5">
          <div class="col-waikuang">
            <div class="col-radio">01</div>
          </div>
          <p class="col-toptext">填写申请表</p>
        </el-col>
        <el-col class="todayrezhao-col" :span="5">
          <div class="col-waikuang">
            <div class="col-radio">02</div>
          </div>
          <p class="col-toptext">提报资料</p>
        </el-col>
        <el-col class="todayrezhao-col" :span="5">
          <div class="col-waikuang">
            <div class="col-radio">03</div>
          </div>
          <p class="col-toptext">资料审核</p>
        </el-col>
        <el-col class="todayrezhao-col" :span="5">
          <div class="col-waikuang">
            <div class="col-radio">04</div>
          </div>
          <p class="col-toptext">注册成功</p>
        </el-col>
      </el-row>
      <el-button class="todayrezhao-button">立即申请</el-button>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import headtop from "../components/header";
import foot from "../components/footer";
export default {
  name: "LinggongdoorTodayrezhao",
  components: {
    headtop,
    foot,
  },
  data() {
    return {
      choseindex: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.rezhaopage {
  background: #f4f5f9;
}
.todayrezhao-banner {
  width: 100%;
  height: auto;
}

.checked {
  box-shadow: 0px 10px 10px 0px #eaeaea;
  border: none !important;
}

.todayrezhao-top {
  background: #fff;
  padding: 50px 0;
  position: relative;
  top: -60px;
  width: 70%;
  left: 15%;
  right: 15%;
  .todayrezhao-title {
    font-size: 32px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .todayrezhao-row {
    margin-top: 50px;
    justify-content: space-around;
  }
  .todayrezhao-col {
    border: 1px solid #e2e2e2;

    padding: 36px 10px;
    .col-img {
      width: 75px;
      height: 75px;
      display: block;
      margin: auto;
    }
    .col-toptext {
      font-size: 22px;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin: 20px 0;
    }
    .col-bottomtext {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      text-align: center;
    }
  }
}

.todayrezhao-center {
  background: #fff;
  padding: 50px 0;
  position: relative;
  top: -30px;
  width: 70%;
  left: 15%;
  right: 15%;
   .todayrezhao-title {
    font-size: 32px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  img{
    width: 90%;
    height: auto;
    display: block;
    margin:  auto;
    margin-top:50px ;
  }
}

.todayrezhao-bottom {
  background-image: url("../assets/img/todayrezhao/bacimg.png");
  background-size: 100% 100%;
  position: relative;
  padding: 50px 0;
  margin-bottom: 30px;
  top: 0;
  width: 70%;
  left: 15%;
  right: 15%;

  .todayrezhao-xuxian {
    width: 94%;
    left: 3%;
    height: 0px;
    border: 1px dashed #979797;
    position: absolute;
    top: 36%;
  }
  .todayrezhao-title {
    font-size: 32px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .todayrezhao-row {
    margin-top: 50px;
    justify-content: space-around;
    .col-waikuang {
      border-radius: 50%;
      width: 83px;
      height: 83px;
      display: flex;
      margin: auto;
      border: 1px solid #67a5e1;
      display: flex;
      align-items: center;
    }
    .col-radio {
      background: #67a5e1;
      border-radius: 50%;
      width: 75px;
      height: 75px;
      text-align: center;
      line-height: 75px;
      display: block;
      margin: auto;
      color: #fff;
      font-size: 22px;
    }
    .col-toptext {
      font-size: 22px;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin: 20px 0;
    }
  }
  .todayrezhao-button {
    width: 40%;
    display: block;
    margin: auto;
    background: #67a5e1;
    border-radius: 10px;
    color: #fff;
    padding: 20px 0;
    font-size: 22px;
    margin-top: 100px;
  }
}
</style>