<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->

		<Header />
		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView" style="background-color: #ff8041;"></div>
				</div>
			</div>
		</div>
		
		

	<div class="bannerDiv">
	
	
		
	
		<img src="../assets/squattingActivity/comingSoon.png" class="comingSoonImg">
	</div>




		<Footer></Footer>
	</div>
</template>

<script>
	import {
		// getBanner,
		// getInfo,
		getCategoryList,
		// getAdvisory,
	} from "../request/api.js";
	import Header from "../components/header";
	// import smallHeader from "../components/smallHeader.vue";
	import Footer from "../components/footer";
	export default {
		name: "index",
		data() {
			return {
				aboutShow: true,
				categoryList: "", // 商城目录

				CultureShow: false,
				callWeShow: false,

			}
		},
		created() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// this.first()
			this.getMailList();
		},

		components: {
			Footer,
			// smallHeader
			Header
		},


		methods: {
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
			getMailList() {
				let params = {
					app_type: "pc",
					city_site_id: 2,
				};
				getCategoryList(params).then((res) => {
					// console.log("getMailList", res);
					this.categoryList = res.data[0].child_list;
				});
			},

			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},

			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})
				localStorage.setItem("palt","1")

			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},




		}

	};
</script>

<style scoped="scoped" lang="scss">
	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;
	
	
	.bannerDiv {
	
		// width: 1440px;
		width: 100%;
		height: 600px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		// margin-bottom: 45px;
		// transform: scale(0.8);
		// margin-top: -20px;
		// margin-bottom: -20px;
		margin-top: 14px;
		overflow: hidden;
	}
	
	.bannerDiv img {
		
		height: 100%;
	}
	
	

	//********标题********
	.title {
			width: 1280px;
			
			box-sizing: border-box;
			min-width: 1280px;
			
			margin: 0 auto;
			height: 35px;
			position: relative;
			z-index: 999;
			display: flex;
			justify-content: space-between;
			margin-bottom: 5px;
	
	
			.titleLeft {
				box-sizing: border-box;
				// width: 240px;
				// width: 360px;
				color: $white;
				float: left;
				// overflow: hidden;
	
	
				.titleLeftNav {
					// font-size: 15px;
					font-size: 15px;
					// line-height: 30px;
					text-align: center;
					// border-bottom: 5px solid #ff8041;
					// box-sizing: border-box;
					width: 344px;
					height: 43px;
					background: #ff8041;
					padding-bottom: 5px;
					font-weight: bold;
					color: #ffffff;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					// padding-bottom: 5px;
	
					b:nth-of-type(1) {
						font-size: 16px;
						font-weight: 700;
	
	
					}
	
	
					b:nth-of-type(2) {
						font-weight: 400;
	
						span {
							padding: 0 10px;
						}
	
	
					}
				}
	
				.bigImgLeft {
					overflow: hidden;
					height: 99%;
					z-index: 999;
					position: absolute;
					height: 653px;
					// width: 240px;
					width: 346px;
					background: #394144;
					box-sizing: border-box;
					padding: 0 31px;
					opacity: 0.9;
	
					// overflow-y: scroll;
	
					.bigImgLeftItem {
						width: 100%;
						margin: 0 auto;
						// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
						color: $white;
						// padding-bottom: 20px;
						// padding: 30px 0;
						box-sizing: border-box;
						margin-top: 20px;
						// height:126px;
						// background-color: red;
	
	
						.bigImgLeftItemTitle {
							font-weight: bold;
							box-sizing: border-box;
							cursor: pointer;
							// padding: 20px 0 15px;
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							align-items: center;
							padding-top: 30px;
							box-sizing: border-box;
							margin-bottom: 14px;
	
	
							.bigImgLeftItemTitleText {
								padding-left: 15px;
								// font-size: 14px;
								font-size: 19px;
								border-left: 1px solid #ffffff;
								color: #fff;
	
	
							}
	
							.bigImgLeftItemTitleText:hover {
								color: #fa7c25;
							}
						}
	
						.bigImgLeftItemInfo {
							// font-size: 14px;
							font-size: 16px;
							line-height: 16px;
							cursor: pointer;
							color: rgba($color: #fff, $alpha: 0.7);
	
	
	
							.bigImgLeftItemInfoInner {
								display: inline-block;
								padding: 8px 0;
	
	
	
							}
	
							.line {
								padding: 0 10px;
							}
	
							.bigImgLeftItemInfoInner:hover {
								color: #fa7c25;
							}
						}
					}
				}
	
				img {
					width: 20px;
					height: 20px;
					vertical-align: middle;
					margin-right: 5px;
					padding-left: 20px;
				}
			}
	
			.titleLeft:hover {
				cursor: pointer;
			}
	
			.titleRightAll {
				display: flex;
	
	
				.titleRight {
					width: 100px;
					line-height: 30px;
					font-size: 17px;
					text-align: center;
					color: #333333;
					margin-left: 20px;
					cursor: pointer;
					padding-bottom: 2px;
					padding-top: 2px;
	
					.lineView {
						width: 100%;
						height: 5px;
						background-color: #ebeaea;
						border-radius: 10px;
						margin-top: 5px;
						
						&.active,
						&:hover {
							width: 100%;
							height: 5px;
							background-color: #ff8041;
							border-radius: 10px;
						
							// border-bottom-color: #ff8041;
							// border-bottom-left-radius: 3px !important;
							// border-bottom-right-radius: 3px !important;
						}
					}
	
	
	
	
				}
	
				// .titleRight+.titleRight {
				// 	&::before {
				// 		content: "";
				// 		position: absolute;
				// 		top: 50%;
				// 		left: 0;
				// 		margin-top: -9px;
				// 		width: 2px;
				// 		height: 18px;
				// 		background-color: #e2e0e0;
				// 	}
				// }
			}
		}
	.comingSoonImg {
		// width: 100%;
		height: 600px;
		margin-top: 10px;
	}
</style>
