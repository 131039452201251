<template>
	<div>
		<!-- 顶部定位及注册登录部分 -->

		<Header />


		<div class="title">
			<div class="titleLeft" @click="toRlm">
				<div class="titleLeftNav"><b>热了吗</b><b><span>·</span>舒适家暖居商城</b></div>

			</div>
			<div class="titleRightAll">
				<div class="titleRight " @click="returnHome">
					<div>网站首页</div>
					<div class="lineView"></div>

				</div>
				<div class="titleRight" @click="solution">
					<div>解决方案</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="industrialPark">
					<div>数字产业园</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="investmentPromotion">
					<div>招商加盟</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="journalism">
					<div>新闻中心</div>
					<div class="lineView" style="background-color: #ff8041;"></div>
				</div>
				<div class="titleRight" @click="helpCenter">
					<div>帮助中心</div>
					<div class="lineView"></div>
				</div>
				<div class="titleRight" @click="aboutWe">
					<div>关于零工儿</div>
					<div class="lineView"></div>
				</div>
			</div>
		</div>



		<!-- <smallHeader />
		<div class="bannerDiv">

			<div class="titleDiv">


				<img src="../assets/introduction/logo.png" class="logoImg">
				<div class="titleDivRightDiv">
					<div @click="solution" class="platPointer">解决方案</div>
					<div @click="industrialPark" class="platPointer">数字产业园</div>
					<div @click="investmentPromotion" class="platPointer">招商加盟</div>
					<div @click="journalism" class="platPointer">新闻中心</div>
					<div @click="helpCenter" class="platPointer">帮助中心</div>
					<div @click="aboutWe" class="platPointer">关于零工儿</div>
					<div class="platPointer">
						<span @click="returnHome">返回首页</span>
						<img src="../assets/introduction/returnHomePage.png" class="returnHomePage">
					</div>
				</div>
			</div> -->

		<!-- <div class="bannerDiv">
			<div class="familyThreeDiv">
				<div class="familyThreeDivCompany">
					<div class="familyDiv">
						<img src="../assets/introduction/familyLogo.png" class="familyLogoImg">
						<div class="familyDivTitle">
							<div>企业 | 家庭 | 市场</div>
							<div>「惠民利企」</div>
						</div>
					</div>
					<div class="familyThreeDivCompanyContent">
						[惠民利企]是零工平台创建的初衷，也是一直以来的理想和目标。 零工人，以此为使命，不断奋斗前行。
					</div>
				</div>
				<img src="../assets/journalism/journalismTop.png" class="familyThreeImg">
			</div>
		</div> -->
		<!-- <div class="lawAbiding">
			<div>News Odd-job</div>
			<div>守法 利他 合规 真实</div>
		</div> -->
		<div class="classificationDivFdiv">
			<div class="classificationDiv">
				<div class="classificationDiv-leftDiv">
					<img src="../assets/icon/location.png" />
					<div>首页/{{nameType}}</div>

				</div>
				<div class="classificationDiv-rightDiv">
					<div v-for="(itemType,index) in newsTypeList" :key="itemType.id">
						<div :class="{backgroundBlue:itemType.styleType==1}" @click="newsTypeClick(index)">
							{{itemType.name}}
						</div>
						<!-- <div :class="{backgroundBlue:rightDivOne}" @click="rightDivOne=true">行业新闻</div> -->
						<!-- <div :class="{backgroundBlue:!rightDivOne}" @click="rightDivOne=false">企业资讯</div> -->
					</div>

				</div>


			</div>
		</div>



		<div class="journalismList ">
			<div class="journalismList-itemView platPointer" @click="journalismDetails(item.post_id)"
				v-for="item in newsList" :key="item.id">
				<div class="journalismList-itemView-leftView">

					<!-- <div>{{item.create_date|formatDate}}</div> -->
					<div class="flexColumn">
						<div>{{item.create_date.split('-')[1]}}</div>
						<div>{{item.create_date.split('-')[2].substring(0,3)}}</div>

					</div>
				</div>
				<div class="journalismList-itemView-centerView">
					<div>
						{{item.post_title}}
					</div>
					<div style="overflow: hidden;max-height:175px" v-html="item.post_excerpt">
						<!-- {{}} -->
						<!-- {{item}} -->
					</div>
				</div>
				<img :src="item.thumbnail">
				<!-- baseImgUrl+ -->
			</div>



			<!-- 	<div class="journalismList-itemView platPointer" @click="journalismDetails">
				<div class="journalismList-itemView-leftView">
					<div>22</div>
					<div>03</div>
				</div>
				<div class="journalismList-itemView-centerView">
					<div>
						假日经济助推灵活用工市场大爆发
					</div>
					<div>
						假日经济（Holiday Economy）
						是指人们利用节假日集中购物、
						集中消费的行为，带动供给、带动市场、
						带动经济发展的一种系统经济模式。
						有人形象地称之为：因为有一部分人休息，
						而使另一部分人获得工作的机会。
					</div>
				</div>
				<img src="../assets/journalism/newsContentTwo.png">

			</div> -->



			<!-- <div class="journalismList-itemView platPointer" @click="journalismDetails">
				<div class="journalismList-itemView-leftView">
					<div>22</div>
					<div>03</div>
				</div>
				<div class="journalismList-itemView-centerView">
					<div>
						四大特征突显零工市场前景广阔
					</div>
					<div>
						新一代信息技术快速发展使得工作场所和工作方式的多元化、
						平台化成为经济发展的主要趋势之一，大大扩展了打零工的场景。
						一切可以外包的，都可以通过零工的形式完成，
						一些以任务外包和工作信息撮合的大型互联网平台成为此轮零工经济的主要推手。
						零工已经不仅是一种工作形式，
						还成为一种席卷全球的商业和经济运行模式。
						当今的零工市场除了传统的兼职、
						打短工以及与之相伴的“马路劳务市场”之外，
						又叠加了鲜明的时代特征。
					</div>
				</div>
				<img src="../assets/journalism/newsContentThree.png">

			</div> -->



			<!-- <div class="journalismList-itemView platPointer" @click="journalismDetails">
				<div class="journalismList-itemView-leftView">
					<div>19</div>
					<div>03</div>
				</div>
				<div class="journalismList-itemView-centerView">
					<div>
						再见 996！你好 灵活用工！
					</div>
					<div>
						随着时代变迁，社会不断发展进步，
						工作岗位上的竞争也越来越激烈，
						工作时间越来越长。以前的朝九晚五、
						朝九晚六渐渐变成996，007，
						使得不少人休息时间变短，
						原来该休息的时间却还在为工作而忙碌着，
						“多劳多得”变了味道。
					</div>
				</div>
				<img src="../assets/journalism/newsContentFour.png">

			</div> -->
			<div class="paginationView" v-if="total>0">
				<el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChange">
				</el-pagination>
			</div>






		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import Config from "../request/config.js";
	import Header from "../components/header";
	// import smallHeader from "../components/smallHeader.vue";
	import Footer from "../components/footer";
	import {
		getNewsList,
		getNewsType
	} from "../request/api.js";
	export default {
		name: "index",
		data() {
			return {
				baseImgUrl: Config.baseImgUrl,
				aboutShow: true,

				CultureShow: false,
				callWeShow: false,
				newsList: [],
				total: "",
				rightDivOne: false,
				newsTypeList: [],
				nameType: "企业资讯",

			}
		},
		created() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// this.first()
		},
		components: {
			Footer,
			// smallHeader
			Header
		},
		mounted() {

			this.NewsType()

		},


		methods: {
			toRlm() {
				window.open("http://www.areyouhot.cn");
			},
			newsTypeClick(index) {
				console.log(index)
				for (var i = 0; i < this.newsTypeList.length; i++) {
					this.newsTypeList[i].styleType = 0
				}
				this.nameType = this.newsTypeList[index].name
				this.newsTypeList[index].styleType = 1
				console.log("运营", this.newsTypeList)

				let params = {
					page: 1,
					pages: 10,
					category_id: this.newsTypeList[index].id
					// post_type:"",
					// category_id:""
				};


				getNewsList(params).then((res) => {
					console.log("新闻列表", res)
					if (res.code == 1) {
						// this.$message({
						// 	message: "获取新闻成功",
						// 	type: "success",
						// });
						this.newsList = res.data.data
						this.total = res.data.total
					} else {
						this.$message.error("获取新闻失败");
					}
				});

			},

			NewsType() {
				getNewsType().then((res) => {
					console.log("新闻列表", res)
					if (res.code == 1) {
						// this.$message({
						// 	message: "获取新闻成功",
						// 	type: "success",
						// });
						res.data.forEach(function(item) {
							item.styleType = 0;
						});

						this.newsTypeList = res.data
						this.newsTypeList[0].styleType = 1

						console.log("加个东西", this.newsTypeList)
						this.mewsList()

					} else {
						this.$message.error("获取新闻失败");
					}
				});
			},

			mewsList() {

				let params = {
					page: 1,
					pages: 10,
					category_id: this.newsTypeList[0].id
					// post_type:"",
					// category_id:""
				};


				getNewsList(params).then((res) => {
					console.log("新闻列表", res)
					if (res.code == 1) {
						// this.$message({
						// 	message: "获取新闻成功",
						// 	type: "success",
						// });
						this.newsList = res.data.data
						this.total = res.data.total
					} else {
						this.$message.error("获取新闻失败");
					}
				});
			},
			currentChange(value) {
				let params = {
					page: value,
					pages: 10,
					// post_type:"",
					// category_id:""
				};


				getNewsList(params).then((res) => {
					console.log("新闻列表", res.data)
					if (res.code == 1) {
						// this.$message({
						// 	message: "获取新闻成功！",
						// 	type: "success",
						// });
						this.newsList = res.data.data
					} else {
						this.$message.error("获取新闻失败");
					}
				});
			},


			investmentPromotion() {
				this.$router.push({
					name: 'investmentPromotion',
					params: {
						content: '1',
					}
				})
			},
			solution() {
				this.$router.push({
					name: 'solution'
				})
			},
			industrialPark() {
				this.$router.push({
					name: 'industrialPark',

				})
			},
			journalism() {
				this.$router.push({
					name: 'journalism',

				})
			},
			journalismDetails(id) {
				this.$router.push({
					name: 'journalismDetails',
					params: {
						id: id
					}
				})
			},

			helpCenter() {
				this.$router.push({
					name: 'helpcenter',

				})
			},
			aboutWe() {
				this.$router.push({
					name: 'PlatformIntroduction',
					params: {
						content: '1',
					}
				})

			},
			returnHome() {
				this.$router.push({
					name: 'index',
					params: {
						content: '1',
					}
				})
			},


		}

	};
</script>

<style scoped lang="scss">
	$font-color: #000000;
	$light-color: #f76a00;
	$white: #ffffff;

	.bannerDiv {

		// width: 1440px;
		// margin: 0 auto;
		// margin-top: 61px;
		// box-sizing: border-box;
		// margin-bottom: 45px;
		// width: 1440px;
		// width: 1380px;
		// width: 1200px;
		width: 1280px;
		margin: 0 auto;
		// margin-top: 61px;
		box-sizing: border-box;
		// margin-bottom: 45px;
		transform: scale(0.8);
		margin-top: -20px;
		margin-bottom: -20px;

	}



	.logoImg {
		width: 197px;
		height: 52px;
	}

	.familyThreeDiv {

		width: 1039px;
		height: 532px;
		margin-top: 80px;
		position: relative;
		margin: 0 auto;
	}

	.familyThreeImg {

		width: 828px;
		height: 532px;
		position: absolute;
		right: 0;


	}

	.familyThreeDivCompany {

		width: 499px;
		height: 396px;
		position: absolute;
		background: linear-gradient(91deg, #f4f6f5 0%, #ffffff 100%);
		left: 0;
		z-index: 11;
		top: 95px;
	}

	.familyDiv {

		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		/* align-items: flex-end; */

		/* background-color: red; */
	}

	.familyLogoImg {

		height: 163px;
		width: 168px;

		margin-top: 47px;
		margin-left: 24px;
	}

	.familyDivTitle {

		margin-top: 105px;
	}

	.familyDivTitle div:nth-child(1) {
		font-size: 36px;
		font-weight: 400;
		text-align: left;
		color: #828282;
		line-height: 52px;
	}

	.familyDivTitle div:nth-child(2) {
		font-size: 48px;
		font-weight: 400;
		text-align: LEFT;
		color: #105697;
		line-height: 45px;
		margin-top: 17px;
	}

	.familyThreeDivCompanyContent {

		font-size: 19px;
		font-weight: Extra Light;
		text-align: LEFT;
		color: #828282;
		margin-top: 57px;
		width: 386px;
		margin-left: 49px;
	}


	.lawAbiding {
		width: 100%;
		height: 276px;
		background: #105697;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		padding-top: 61px;
		padding-bottom: 100px;
		box-sizing: border-box;
	}

	.lawAbiding div:nth-child(1) {
		font-size: 40px;
		font-weight: bold;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.lawAbiding div:nth-child(2) {
		font-size: 40px;
		font-weight: 400;
		text-align: left;
		color: #fefefe;
		line-height: 21px;
	}

	.journalismList {
		// width: 1531px;
		width: 1280px;
		// height: 1299px;
		margin: 0 auto;

		margin-top: 10px;
		margin-bottom: 100px;
		padding-top: 49px;
		box-sizing: border-box;
	}

	.journalismList-itemView {
		height: 284px;
		width: 100%;

		border-top: 2px solid #e8e8e8;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 30px;
		box-sizing: border-box;
	}

	.journalismList-itemView-leftView {
		width: 101px;
		height: 95px;
		border: 4px solid #333;
		font-size: 20px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}

	// .journalismList-itemView-leftView div:nth-child(1) {
	// 	margin-top: 17px;

	// }

	// .journalismList-itemView-leftView div:nth-child(2) {
	// 	padding-bottom: 19px;
	// }

	.journalismList-itemView-centerView {
		// width: 1008px;
		width: 700px;
		height: 162px;
	}

	.journalismList-itemView-centerView div:nth-child(1) {
		// font-size: 28px;
		// font-size: 36px;
		font-size: 24px;
		font-weight: 400;
		text-align: left;
		color: #363636;
		line-height: 21px;
		margin-bottom: 26px;
	}

	.journalismList-itemView-centerView div:nth-child(2) {
		// font-size: 18px;
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		color: #666666;
		// line-height: 30px;
		line-height: 36px;
	}

	.journalismList-itemView img {
		// width: 336px;
		// height: 237px;
		height: 220px;

	}

	.paginationView {
		/* height: 297px; */
		width: 100%;
		/* background-color: #0000EE; */
		border-top: 2px solid #e8e8e8;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 30px;
		box-sizing: border-box;
	}

	.titleDiv {
		width: 100%;
		/* height: 52px; */
		display: flex;
		font-size: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 80px;


	}

	.titleDivRightDiv {
		/* width: 6.21rem; */
		/* width: 621px; */
		width: 1000px;
		height: 100%;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center
	}

	.titleDivRightDiv div {

		font-size: 16px;
		text-align: CENTER;
		color: #2f2e2e;
	}

	.logoImg {
		width: 197px;
		height: 52px;
	}

	.platPointer {
		cursor: pointer;
	}

	.returnHomePage {
		width: 13px;
		height: 13px;
		margin-left: 7px;
	}

	.title {
		width: 1280px;

		box-sizing: border-box;
		min-width: 1280px;

		margin: 0 auto;
		height: 35px;
		position: relative;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;


		.titleLeft {
			box-sizing: border-box;
			// width: 240px;
			// width: 360px;
			color: $white;
			float: left;
			// overflow: hidden;


			.titleLeftNav {
				font-size: 15px;
				// line-height: 30px;
				text-align: center;
				// border-bottom: 5px solid #ff8041;
				// box-sizing: border-box;
				width: 344px;
				height: 43px;
				background: #ff8041;
				padding-bottom: 5px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// padding-bottom: 5px;


				b:nth-of-type(1) {
					font-size: 16px;
					font-weight: 700;


				}


				b:nth-of-type(2) {
					font-weight: 400;

					span {
						padding: 0 10px;
					}


				}
			}

			.bigImgLeft {
				overflow: hidden;
				height: 99%;
				z-index: 999;
				position: absolute;
				height: 653px;
				// width: 240px;
				width: 346px;
				background: #394144;
				box-sizing: border-box;
				padding: 0 31px;
				opacity: 0.9;

				// overflow-y: scroll;

				.bigImgLeftItem {
					width: 100%;
					margin: 0 auto;
					// border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
					color: $white;
					// padding-bottom: 20px;
					// padding: 30px 0;
					box-sizing: border-box;
					margin-top: 20px;
					// height:126px;
					// background-color: red;


					.bigImgLeftItemTitle {
						font-weight: bold;
						box-sizing: border-box;
						cursor: pointer;
						// padding: 20px 0 15px;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						box-sizing: border-box;
						margin-bottom: 14px;


						.bigImgLeftItemTitleText {
							padding-left: 15px;
							// font-size: 14px;
							font-size: 19px;
							border-left: 1px solid #ffffff;
							color: #fff;


						}

						.bigImgLeftItemTitleText:hover {
							color: #fa7c25;
						}
					}

					.bigImgLeftItemInfo {
						// font-size: 14px;
						font-size: 16px;
						line-height: 16px;
						cursor: pointer;
						color: rgba($color: #fff, $alpha: 0.7);



						.bigImgLeftItemInfoInner {
							display: inline-block;
							padding: 8px 0;



						}

						.line {
							padding: 0 10px;
						}

						.bigImgLeftItemInfoInner:hover {
							color: #fa7c25;
						}
					}
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
				padding-left: 20px;
			}
		}

		.titleLeft:hover {
			cursor: pointer;
		}

		.titleRightAll {
			display: flex;


			.titleRight {
				width: 100px;
				line-height: 30px;
				font-size: 17px;
				text-align: center;
				color: #333333;
				margin-left: 20px;
				cursor: pointer;
				padding-bottom: 2px;
				padding-top: 2px;

				.lineView {
					// border-bottom-left-radius: 3px !important;
					// border-bottom-right-radius: 3px !important;
					// border-bottom: 5px solid #ebeaea;
					width: 100%;
					height: 5px;
					background-color: #ebeaea;
					border-radius: 10px;
					margin-top: 5px;

					&.active,
					&:hover {
						width: 100%;
						height: 5px;
						background-color: #ff8041;
						border-radius: 10px;

						// border-bottom-color: #ff8041;
						// border-bottom-left-radius: 3px !important;
						// border-bottom-right-radius: 3px !important;
					}
				}




			}

			// .titleRight+.titleRight {
			// 	&::before {
			// 		content: "";
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		margin-top: -9px;
			// 		width: 2px;
			// 		height: 18px;
			// 		background-color: #e2e0e0;
			// 	}
			// }
		}
	}






	.classificationDivFdiv {
		width: 100%;
		background-color: rgb(243, 243, 243);
	}



	.classificationDiv {
		height: 70px;
		width: 1280px;
		margin: 0 auto;
		background-color: rgb(243, 243, 243);
		// padding: 7px 0 9px 20vw;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 14px;
	}

	.classificationDiv-leftDiv {
		font-size: 16px;
		color: rgb(102, 102, 102);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 300px;
	}

	.classificationDiv-leftDiv img {
		margin-right: 19px;
		// width:20px;
		height: 22px;
	}

	.classificationDiv-rightDiv {
		width: 391px;
		height: 54px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		// margin-left: 20%;
	}

	.classificationDiv-rightDiv div {
		width: 182px;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 16px;
	}

	.backgroundBlue {
		background-color: rgb(15, 85, 151);
		color: #fff;
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>
