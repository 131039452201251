<template>
  <div>
    <headtop></headtop>
    <img class="bannerimg" src="../assets/img/news/banner.png" />
    <div
      v-for="(item, index) in listpage"
      :key="index"
      :class="isactive == index ? 'activeindex' : ''"
      @mouseover="chagebig(index)"
      @mouseout="remove"
      class="listclass"
      @click="gotodetail(item.post_id)"
    >
      <img class="listimg" :src="baseImgUrl + item.thumbnail" />
      <div class="listright">
        <div class="listright-top">
          <div class="top-title">{{ item.post_title }}</div>
          <div class="top-time">{{ item.published_time }}</div>
        </div>
        <div class="listright-bottom">
          <div class="bottom-neirong">{{ item.post_content }}</div>
          <img src="../assets/img/news/next.png" />
        </div>
      </div>
    </div>
    <el-pagination
      class="fenye"
      background
      :page-size="3"
      layout="prev, pager, next"
      :total="pagetotal"
      @prev-click="lastpage"
      @next-click="nextpage"
      @current-change="currentchangepage"
    >
    </el-pagination>
    <foot></foot>
  </div>
</template>

<script>
import Config from "../request/config.js";
import headtop from "../components/header";
import foot from "../components/footer";
import { getInfo } from "../request/api";
export default {
  name: "VueNews",
  components: {
    headtop,
    foot,
  },
  data() {
    return {
      baseImgUrl: Config.baseImgUrl,
      listpage: [],
      isactive: -1,
      page: 1,
      categoryid: "",
      pagetotal: 0,
    };
  },
  mounted() {
    window.scrollTo(0,0);
    this.getpage();
  },
  created() {
    this.categoryid = this.$route.params.categoryid;
    if (this.$route.params.categoryid) {
      localStorage.setItem("categoryid", this.$route.params.categoryid);
    }
  },
  methods: {
    chagebig(index) {
      this.isactive = index;
    },
    lastpage() {
      this.page = this.page - 1;
      this.getpage();
    },
    nextpage() {
      this.page = this.page + 1;
      this.getpage();
    },
    currentchangepage(index) {
      this.page = index;
      this.getpage();
    },
    remove() {
      this.isactive = -1;
    },
    getpage() {
      var data = {
        page: this.page,
        pages: "3",
        post_type: "1",
        category_id: this.categoryid||localStorage.getItem("categoryid"),
      };
      getInfo(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.listpage = res.data.data;
          this.pagetotal = res.data.total;
          this.pagesize = res.data.per_page;
        }
      });
    },
    gotodetail(id) {
      this.$router.push({ name: "other", params: { postid: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #204fc4;
}
.bannerimg {
  width: 100%;
  height: 260px;
}

.activeindex {
  border: none !important;
  box-shadow: 0px 0px 15px #ddd;
}

.listclass {
  border-bottom: 1px solid #dcdcdc;
  padding: 20px 30px;
  width: 60%;
  margin: 50px 20%;
  display: flex;
  .listimg {
    width: 258px;
    height: 164px;
    display: block;
    margin: auto 0;
  }
  .listright {
    margin-left: 3%;
    width: 70%;
    padding: 15px 0 0;
    .listright-top {
      display: flex;
      justify-content: space-between;
      .top-title {
        font-size: 18px;
        color: #333;
        width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .top-time {
        font-size: 18px;
        color: #333;
      }
    }
    .listright-bottom {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      margin-top: 20px;
      .bottom-neirong {
        font-size: 14px;
        color: #828282;
        width: 90%;
      }
      img {
        width: 37px;
        height: 22px;
        display: block;
        margin: auto 0;
      }
    }
  }
}

.fenye {
  text-align: center;
  margin-bottom: 80px;
}
</style>