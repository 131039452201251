import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import Cookies from 'js-cookie';
import {
	Message
} from 'element-ui';
import 'lib-flexible'


// import moment from 'moment' // 可以不用use






Vue.use(ElementUI);

Vue.config.productionTip = false
document.title = '零工儿'
//全局路由守卫
router.beforeEach((to, from, next) => {
	if (to.meta.permission) {
		if (Cookies.get("token")) {
			next();
		} else {
			Message.error("请先登录")
			next("/login");
		}
	} else {
		next();
	}
});

// 将时间戳转日期格式的过滤器
Vue.filter('formatDate', (dataStr) => {
	var time = new Date(dataStr);

	function timeAdd0(str) {
		if (str < 10) {
			str = '0' + str;
		}
		return str
	}
	var y = time.getFullYear();
	var m = time.getMonth() + 1;
	var d = time.getDate();
	var h = time.getHours();
	var mm = time.getMinutes();
	var s = time.getSeconds();
	return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(
		s);
})




new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
