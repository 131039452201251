<template>
	<div>
		<headtop></headtop>
		<img class="bannerimg" src="../assets/img/news/banner.png" />
		<!-- <img src="../assets/img/other/map.png" class="map">  -->
		<div class="top">
			<h1 class="biaoti">{{ info.post_title }}</h1>
			<div class="time">发布时间：{{ info.published_time }}</div>
		</div>

		<div class="detail">
			{{ info.post_content }}
		</div>

		<foot></foot>
	</div>
</template>

<script>
	import headtop from "../components/header";
	import foot from "../components/footer";
	import {
		getInfodetail
	} from "../request/api";
	export default {
		name: "VueOther",
		components: {
			headtop,
			foot,
		},
		data() {
			return {
				postid: "",
				info: {
					title: "新闻",
				},
			};
		},

		mounted() {
			window.scrollTo(0, 0);
			this.getpage();
		},
		created() {
			if (this.$route.params.postid) {
				this.postid = this.$route.params.postid;
			} else {
				this.postid = this.$route.query.postid;
			}

		},
		methods: {
			getpage() {
				var data = {
					post_id: this.postid,
				};
				getInfodetail(data).then((res) => {
					console.log(res);
					if (res.code == 1) {
						this.info = res.data;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.bannerimg {
		width: 100%;
		height: 260px;
	}

	.top {
		border-bottom: 1px solid #ddd;
		padding-bottom: 30px;

		.time {
			text-align: left;
			padding: 0px 10%;
			color: #333;
		}

		.biaoti {
			text-align: left;
			font-size: 42px;
			margin: 30px 10%;
		}
	}

	.detail {
		font-size: 18px;
		font-weight: 400;
		color: #666666;
		width: 80%;
		margin: 0 auto;
		margin-bottom: 150px;
		margin-top: 30px;
	}
</style>
